import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, updateDoc, collection, getDocs, runTransaction, getDoc, increment } from 'firebase/firestore';
import { db } from '../../services/firebase';
import TestIntroduction from './TestIntroduction';
import QuestionContainer from './QuestionContainer';
import TestProgress from './TestProgress';
import TestSubmission from './TestSubmission';
import TestResults from './TestResults';
import CPTestSuccess from './CPTestSuccess';

const CPTest = () => {
  const [darkMode, setDarkMode] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isExternalUser = location.state?.isExternalUser || false;
  const registrationId = location.state?.registrationId;
  const userId = location.state?.userId;
  const userIdentifier = isExternalUser ? registrationId : userId;
  const isGuest = !userIdentifier;

  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [testStatus, setTestStatus] = useState('not started');
  const [score, setScore] = useState(0);
  const [isAnswered, setIsAnswered] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [testCompletionTime, setTestCompletionTime] = useState(null);
  const [testPassed, setTestPassed] = useState(false);

  const [attempts, setAttempts] = useState(0);
  const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const TOTAL_QUESTIONS = 20;
  const PASSING_THRESHOLD = 14; 




  // Fetch questions from Firebase
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const questionBankRef = collection(db, 'questionBank');
        const querySnapshot = await getDocs(questionBankRef);
        const fetchedQuestions = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setQuestions(fetchedQuestions);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching questions:', err);
        setError('Failed to load questions. Please try again later.');
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    if (!registrationId && !userId) {
      navigate('/cp-request', { replace: true });
    }
  }, [registrationId, userId, navigate]);

  useEffect(() => {
    if (!location.state) {
      navigate('/cp-request', { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    const fetchAttempts = async () => {
      if (!isGuest && userIdentifier) {
        try {
          const docRef = doc(db, 'CPRegister', userIdentifier);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const currentAttempts = docSnap.data().testAttempts || 0;
            setAttempts(currentAttempts);
            setMaxAttemptsReached(currentAttempts >= 2);
          }
        } catch (error) {
          console.error('Error fetching attempts:', error);
        }
      }
    };
  
    fetchAttempts();
  }, [userIdentifier, isGuest]);

  const passingScore = 70;

  const selectRandomQuestions = useCallback(() => {
    if (questions.length === 0) return [];
    const shuffled = [...questions].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 20);
  }, [questions]);

  const handleStartTest = () => {
    if (maxAttemptsReached) {
      setError('You have reached the maximum number of attempts');
      return;
    }
    if (questions.length === 0) {
      setError('No questions available. Please try again later.');
      return;
    }
    const selectedQs = selectRandomQuestions();
    setSelectedQuestions(selectedQs);
    setTestStatus('in-progress');
    setUserAnswers(new Array(selectedQs.length).fill([]));
    setSelectedAnswers([]);
  };

  const updateAnswer = (answers) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestion] = answers;
    setUserAnswers(newUserAnswers);
    setIsAnswered(answers.length > 0);
  };

  const validateAnswers = (currentAnswers, question) => {
    if (!currentAnswers || currentAnswers.length === 0) {
      return "Please select an answer before proceeding";
    }
    if (question.multipleAnswers && currentAnswers.length !== question.correctAnswers.length) {
      return `Please select exactly ${question.correctAnswers.length} answers`;
    }
    return "";
  };

  const nextQuestion = () => {
    const currentQuestionObj = selectedQuestions[currentQuestion];
    const error = validateAnswers(userAnswers[currentQuestion], currentQuestionObj);
    
    if (error) {
      setErrorMessage(error);
      return;
    }
    
    setErrorMessage('');
    if (currentQuestion < selectedQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswers(userAnswers[currentQuestion + 1] || []);
      setIsAnswered(userAnswers[currentQuestion + 1]?.length > 0);
    }
  };
  const previousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedAnswers(userAnswers[currentQuestion - 1] || []);
      setIsAnswered(userAnswers[currentQuestion - 1]?.length > 0);
    }
  };

  const handleAnswerSelected = (answers) => {
    setSelectedAnswers(answers);
    updateAnswer(answers);
  };

  const calculateScore = () => {
    let correctAnswers = 0;
    
    userAnswers.forEach((userAnswer, index) => {
      const question = selectedQuestions[index];
      
      if (!userAnswer?.length || !question?.correctAnswers?.length) return;
      
      // Convert answers to indices if they're full text
      const userIndices = userAnswer.map(ans => question.options.indexOf(ans));
      const correctIndices = question.correctAnswers.map(Number);
      
      if (question.multipleAnswers) {
        const sortedUser = [...userIndices].sort((a,b) => a-b);
        const sortedCorrect = [...correctIndices].sort((a,b) => a-b);
        
        if (JSON.stringify(sortedUser) === JSON.stringify(sortedCorrect)) {
          correctAnswers++;
        }
      } else {
        if (userIndices[0] === correctIndices[0]) {
          correctAnswers++;
        }
      }
    });
    
    const percentage = Math.round((correctAnswers / selectedQuestions.length) * 100);
    const passed = percentage >= 70;
    
    return { correctAnswers, percentage, passed };
  };
 
  const processWrongAnswers = () => {
    return selectedQuestions.reduce((wrong, question, index) => {
      const userAnswer = userAnswers[index];
      
      if (!userAnswer || !question.correctAnswers) return wrong;
  
      let isCorrect = false;
      if (question.multipleAnswers) {
        const sortedUser = [...userAnswer].sort();
        const sortedCorrect = [...question.correctAnswers].sort();
        isCorrect = JSON.stringify(sortedUser) === JSON.stringify(sortedCorrect);
      } else {
        isCorrect = userAnswer[0] === question.options[question.correctAnswers[0]];
      }
  
      if (!isCorrect) {
        wrong.push({
          questionText: question.text,
          options: question.options,
          correctAnswers: question.correctAnswers.map(idx => question.options[idx]),
          userAnswers: userAnswer,
          multipleAnswers: question.multipleAnswers
        });
      }
  
      return wrong;
    }, []);
  };
  
  const updateCertificationStatus = async (passed, rawScore) => {
    if (!isGuest) {
      const cpRegisterRef = doc(db, 'CPRegister', userIdentifier);
      const currentDate = new Date();
      const wrongAnswers = processWrongAnswers();
      const newAttemptCount = attempts + 1;
      
      try {
        if (passed) {
          await runTransaction(db, async (transaction) => {
            const counterRef = doc(db, 'CpCertificationNumber', 'counter');
            const counterDoc = await transaction.get(counterRef);
            
            if (!counterDoc.exists()) {
              throw new Error('Counter document not found');
            }
  
            const newNumber = counterDoc.data().latestNumber + 1;
            
            transaction.update(counterRef, { latestNumber: newNumber });
            
            transaction.update(cpRegisterRef, {
              testStatus: 'PASSED',
              lastTestAttempt: currentDate,
              rawScore: rawScore,
              testScore: Math.round((rawScore / selectedQuestions.length) * 100),
              testPassed: true,
              lastTestDetails: {
                totalQuestions: selectedQuestions.length,
                wrongAnswers: wrongAnswers,
                timestamp: currentDate
              },
              certificationNumber: newNumber,
              certificationDate: currentDate,
              testAttempts: newAttemptCount
            });
          });
        } else {
          await updateDoc(cpRegisterRef, {
            testStatus: 'FAILED',
            lastTestAttempt: currentDate,
            rawScore: rawScore,
            testScore: Math.round((rawScore / selectedQuestions.length) * 100),
            testPassed: false,
            lastTestDetails: {
              totalQuestions: selectedQuestions.length,
              wrongAnswers: wrongAnswers,
              timestamp: currentDate
            },
            testAttempts: newAttemptCount
          });
        }
        
        setAttempts(newAttemptCount);
        setMaxAttemptsReached(newAttemptCount >= 2);
        
      } catch (error) {
        console.error('Error updating certification status:', error);
        throw error;
      }
    }
  };

  const handleSubmitTest = async () => {
    try {
      const result = calculateScore();
      setScore(result.correctAnswers);
      setTestPassed(result.passed);
      
      await updateCertificationStatus(result.passed, result.correctAnswers);
      
      setTestCompletionTime(new Date().toISOString());
      setTestStatus('completed');
      
      console.log('Test submitted successfully:', {
        score: result.correctAnswers,
        percentage: result.percentage,
        passed: result.passed
      });
    } catch (error) {
      console.error('Failed to submit test:', error);
      alert('There was an error submitting your test. Please try again.');
    }
  };

  const handleRetakeTest = () => {
    const newQuestions = selectRandomQuestions();
    setSelectedQuestions(newQuestions);
    setCurrentQuestion(0);
    setUserAnswers(new Array(newQuestions.length).fill([]));
    setScore(0);
    setTestStatus('not started');
    setIsAnswered(false);
  };

  const handleReturnToDashboard = () => {
    navigate('/dashboard');
  };

  

  if (loading) {
    return (
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
        <div className="container mx-auto px-4 py-8 flex justify-center items-center">
          <div className="text-xl">Loading questions...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
        <div className="container mx-auto px-4 py-8">
          <div className="text-xl text-red-500 text-center">{error}</div>
          <button 
            onClick={() => window.location.reload()}
            className={`mt-4 px-4 py-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white mx-auto block`}
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-center">CP Test Module</h1>
        <div className="max-w-3xl mx-auto">
          {testStatus === 'not started' && (
            <TestIntroduction onStartTest={handleStartTest} darkMode={darkMode} />
          )}
           {testStatus === 'in-progress' && (
    <>
      <TestProgress 
        currentQuestion={currentQuestion}
        totalQuestions={selectedQuestions.length}
        darkMode={darkMode}
      />
      <QuestionContainer 
        question={selectedQuestions[currentQuestion]}
        onAnswerSelected={handleAnswerSelected}
        selectedAnswers={selectedAnswers}
        darkMode={darkMode}
      />
      {errorMessage && (
        <div className={`mt-4 p-3 rounded ${darkMode ? 'bg-red-900 text-red-200' : 'bg-red-100 text-red-700'}`}>
          {errorMessage}
        </div>
      )}
      <div className="flex justify-between mt-6">
        <button 
          onClick={previousQuestion} 
          disabled={currentQuestion === 0}
          className={`px-4 py-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white disabled:opacity-50`}
        >
          Previous
        </button>
        <button 
          onClick={nextQuestion} 
          disabled={currentQuestion === selectedQuestions.length - 1}
          className={`px-4 py-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white disabled:opacity-50`}
        >
          Next
        </button>
      </div>
      {currentQuestion === selectedQuestions.length - 1 && userAnswers[currentQuestion]?.length > 0 && (
        <TestSubmission onSubmitTest={handleSubmitTest} darkMode={darkMode} />
      )}
    </>
  )}
          {testStatus === 'completed' && testPassed && (
            <CPTestSuccess
              score={score}
              totalQuestions={selectedQuestions.length}
              testCompletionTime={testCompletionTime}
              darkMode={darkMode}
            />
          )}
            {testStatus === 'completed' && !testPassed && (
    <TestResults
      score={score}
      totalQuestions={selectedQuestions.length}
      passingScore={passingScore}
      onRetakeTest={handleRetakeTest}
      onReturnToDashboard={handleReturnToDashboard}
      darkMode={darkMode}
      userAnswers={userAnswers}
      selectedQuestions={selectedQuestions}
      testCompletionTime={testCompletionTime}
      maxAttemptsReached={maxAttemptsReached}
      attempts={attempts}
    />
  )}
        </div>
      </div>
    </div>
  );
};

export default CPTest;