import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignaturePad = ({ onSave }) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const signaturePadRef = useRef(null);

  const handleClear = () => {
    signaturePadRef.current.clear();
    setIsEmpty(true);
  };

  const handleSave = () => {
    if (signaturePadRef.current.isEmpty()) {
      alert('Please provide a signature');
      return;
    }

    // Get the signature as a data URL
    const dataUrl = signaturePadRef.current.toDataURL('image/png');
    onSave(dataUrl);
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-white">Signature</label>
      <div className="border border-gray-600 rounded-md p-4 bg-gray-700">
        <SignatureCanvas
          ref={signaturePadRef}
          onBegin={() => setIsEmpty(false)}
          canvasProps={{
            className: 'signature-canvas',
            width: 400,
            height: 150,
          }}
          backgroundColor="rgba(255, 255, 255, 0.1)"
        />
      </div>
      <div className="flex space-x-2">
        <button
          type="button"
          onClick={handleClear}
          className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
        >
          Clear
        </button>
        <button
          type="button"
          onClick={handleSave}
          disabled={isEmpty}
          className={`px-4 py-2 text-white rounded-md transition-colors ${
            isEmpty ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
          }`}
        >
          Save Signature
        </button>
      </div>
    </div>
  );
};

export default SignaturePad;