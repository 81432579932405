import React from 'react';

const FormattedDate = () => {
  const currentDate = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);

  return (
    <div className="text-lg font-semibold">
      {formattedDate}
    </div>
  );
};

export default FormattedDate;