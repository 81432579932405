import React from 'react';
import LOATemplate from '../PermitsStatus/templates/LOATemplate';
import EPTWTemplate from '../PermitsStatus/templates/EPTWTemplate';
import MPTWTemplate from '../PermitsStatus/templates/MPTWTemplate';
import { generatePermitPDF } from '../PermitsStatus/services/pdfService';


const ViewPermitModal = ({ isOpen, onClose, permitData }) => {
  if (!isOpen) return null;

  console.log('ViewPermitModal permitData:', permitData); // Debug log

  const handlePDFDownload = () => {
    const templateId = `${permitData.type.toLowerCase()}-template`;
    console.log('Template ID:', templateId); // Debug log
    
    // Add null checks and default values
    const number = permitData?.number || permitData?.permitNumber || '000';
    console.log('Permit Number:', number); // Debug log
    
    const permitNumber = `${permitData.type}-WTX-${number}`;
    console.log('Full Permit Number:', permitNumber); // Debug log
    
    generatePermitPDF(templateId, permitData.type, permitNumber);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {permitData.type === 'LOA' && (
          <div id="loa-template">
            <LOATemplate permitData={permitData} />
          </div>
        )}
        {permitData.type === 'EPTW' && (
          <div id="eptw-template">
            <EPTWTemplate permitData={permitData} />
          </div>
        )}
        {permitData.type === 'MPTW' && (
          <div id="mptw-template">
            <MPTWTemplate permitData={permitData} />
          </div>
        )}
        <div className="mt-4 flex space-x-4">
          <button
            onClick={handlePDFDownload}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Download PDF ({permitData.type}-WTX-{permitData?.number || '000'})
          </button>
          <button
            onClick={onClose}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewPermitModal;
