import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../../services/firebase';
import debounce from 'lodash/debounce';

const SearchableAssetInput = ({ value, onChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [cache, setCache] = useState({});

  const searchAssets = useCallback(
    debounce(async (term) => {
      if (term.length < 2) {
        setSearchResults([]);
        setShowDropdown(false);
        return;
      }

      const upperCaseTerm = term.toUpperCase();

      if (cache[upperCaseTerm]) {
        setSearchResults(cache[upperCaseTerm]);
        setShowDropdown(true);
        return;
      }

      const assetsRef = collection(db, 'Assets');
      const q = query(
        assetsRef,
        where('Asset', '>=', upperCaseTerm),
        where('Asset', '<=', upperCaseTerm + '\uf8ff'),
        limit(10)
      );
      
      const querySnapshot = await getDocs(q);
      const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      setCache(prevCache => ({ ...prevCache, [upperCaseTerm]: results }));
      setSearchResults(results);
      setShowDropdown(true);
    }, 300),
    [cache]
  );

  useEffect(() => {
    searchAssets(searchTerm);
  }, [searchTerm, searchAssets]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAssetSelect = (asset) => {
    const updatedAssets = selectedAssets.some(a => a.id === asset.id)
      ? selectedAssets
      : [...selectedAssets, asset];
    setSelectedAssets(updatedAssets);
    onChange(updatedAssets);
    setSearchTerm('');
    setShowDropdown(false);
  };

  const handleRemoveAsset = (assetToRemove) => {
    const updatedAssets = selectedAssets.filter(asset => asset.id !== assetToRemove.id);
    setSelectedAssets(updatedAssets);
    onChange(updatedAssets);
  };

  return (
    <div className="w-full max-w-md">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Search for assets (min. 2 characters)..."
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {showDropdown && searchResults.length > 0 && (
          <ul className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
            {searchResults.map((asset) => (
              <li
                key={asset.id}
                onClick={() => handleAssetSelect(asset)}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                {asset.Asset}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="mt-4 flex flex-wrap gap-2">
        {selectedAssets.map((asset) => (
          <div key={asset.id} className="bg-blue-100 px-3 py-1 rounded-full flex items-center">
            <span className="mr-2">{asset.Asset}</span>
            <button
              onClick={() => handleRemoveAsset(asset)}
              className="text-blue-500 hover:text-blue-700 focus:outline-none"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchableAssetInput;
