import React, { useState, useEffect } from 'react';
import usePermits from './hooks/usePermits';
import { usePermitContext } from './context/PermitContext';
import { permitStatusHelper } from './utils/permitStatusHelper';
import ViewPermitModal from '../modals/ViewPermitModal';
import IssuePermitModal from '../modals/IssuePermitModal';
import TransferPermitModal from '../modals/TransferPermitModal';

import PermitTypesTabs from './PermitTypesTabs';
import StatusTabs from './StatusTabs';
import PermitList from './PermitList';
import PermitService from './services/PermitService';

function PermitsStatus() {
  const { 
    loading, 
    fetchPermits, 
    handleIssuePermit, 
    handleClosePermit,
    handleClearancePermit 
  } = usePermits();
  
  const { getStatusColor } = permitStatusHelper;
  
  // State variables
  const [activePermitType, setActivePermitType] = useState('LOA');
  const [activeStatus, setActiveStatus] = useState('Prepared');
  const [permits, setPermits] = useState([]);
  const [selectedPermit, setSelectedPermit] = useState(null);
  const [isIssueModalOpen, setIsIssueModalOpen] = useState(false);
  const [permitToIssue, setPermitToIssue] = useState(null);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [permitToTransfer, setPermitToTransfer] = useState(null);
  const [statusCounts, setStatusCounts] = useState({
    Prepared: 0,
    Issued: 0,
    Expired: 0,
    Clearance: 0,
    Closed: 0
  });

  const handleViewPermit = (permit) => {
    setSelectedPermit(permit);
  };

  const { refreshTrigger } = usePermitContext();

  useEffect(() => {
    const loadPermits = async () => {
      const fetchedPermits = await fetchPermits(activePermitType, activeStatus);
      setPermits(fetchedPermits);
    };
    loadPermits();
  }, [activePermitType, activeStatus, refreshTrigger]);

  useEffect(() => {
    const fetchStatusCounts = async () => {
      try {
        const counts = await PermitService.getPermitCountsByType(activePermitType);
        setStatusCounts(counts);
      } catch (error) {
        console.error('Error fetching status counts:', error);
      }
    };

    fetchStatusCounts();
  }, [activePermitType, refreshTrigger]);

  const handleOpenIssueModal = (permit) => {
    setPermitToIssue(permit);
    setIsIssueModalOpen(true);
  };

  const handleCloseIssueModal = () => {
    setPermitToIssue(null);
    setIsIssueModalOpen(false);
  };

  const onIssuePermit = async (permitData) => {
    try {
      const result = await handleIssuePermit(permitData.id, permitData);
      if (result) {
        const fetchedPermits = await fetchPermits(activePermitType, activeStatus);
        setPermits(fetchedPermits);
        handleCloseIssueModal();
      }
    } catch (error) {
      console.error('Error issuing permit:', error);
    }
  };

  const onClosePermit = async (closeData) => {
    console.log('PermitsStatus - onClosePermit received:', closeData);
    try {
      const result = await handleClosePermit(closeData);
      if (result) {
        const fetchedPermits = await fetchPermits(activePermitType, activeStatus);
        setPermits(fetchedPermits);
        const counts = await PermitService.getPermitCountsByType(activePermitType);
        setStatusCounts(counts);
      }
    } catch (error) {
      console.error('PermitsStatus - Error closing permit:', error);
    }
  };

  // Add new clearance handler
  const onClearancePermit = async (clearanceData) => {
    console.log('PermitsStatus - onClearancePermit received:', clearanceData);
    try {
      const result = await handleClearancePermit(clearanceData);
      if (result) {
        const fetchedPermits = await fetchPermits(activePermitType, activeStatus);
        setPermits(fetchedPermits);
        const counts = await PermitService.getPermitCountsByType(activePermitType);
        setStatusCounts(counts);
      }
    } catch (error) {
      console.error('PermitsStatus - Error clearing permit:', error);
    }
  };

  const handleOpenTransferModal = (permit) => {
    setPermitToTransfer(permit);
    setIsTransferModalOpen(true);
  };

  const handleCloseTransferModal = () => {
    setPermitToTransfer(null);
    setIsTransferModalOpen(false);
  };

  const onTransferPermit = async (transferData) => {
    try {
      const result = await PermitService.transferPermit(transferData.id, transferData);
      if (result) {
        const fetchedPermits = await fetchPermits(activePermitType, activeStatus);
        setPermits(fetchedPermits);
        setIsTransferModalOpen(false);
      }
    } catch (error) {
      console.error('PermitsStatus - Error transferring permit:', error);
    }
  };

  if (loading) {
    return <div className="text-white">Loading permits...</div>;
  }

  return (
    <div className="bg-gray-900 text-gray-100 p-6 rounded-lg shadow-lg">
      <PermitTypesTabs 
        activePermitType={activePermitType}
        setActivePermitType={setActivePermitType}
      />
      
      <StatusTabs 
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        statusCounts={statusCounts}
      />
      
      <PermitList
        permits={permits}
        handleViewPermit={handleViewPermit}
        handleIssuePermit={onIssuePermit}
        handleClosePermit={onClosePermit}
        handleTransferPermit={onTransferPermit}
        handleClearancePermit={onClearancePermit}
      />
      
      <ViewPermitModal 
        isOpen={!!selectedPermit}
        onClose={() => setSelectedPermit(null)}
        permitData={selectedPermit}
      />

      <IssuePermitModal
        isOpen={isIssueModalOpen}
        onClose={handleCloseIssueModal}
        onIssue={onIssuePermit}
        permitData={permitToIssue}
      />

      <TransferPermitModal
        isOpen={isTransferModalOpen}
        onClose={handleCloseTransferModal}
        onTransfer={onTransferPermit}
        permitData={permitToTransfer}
      />
    </div>
  );
};

export default PermitsStatus;