import html2pdf from 'html2pdf.js';

export const generatePermitPDF = async (elementId, permitType, permitNumber) => {
  const element = document.getElementById(elementId);
  
  const opt = {
    margin: 0,
    filename: `${permitNumber}.pdf`, // Now using the full permit number in the filename
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { 
      scale: 2,
      useCORS: true,
      letterRendering: true,
    },
    jsPDF: { 
      unit: 'mm', 
      format: 'a4', 
      orientation: 'portrait' 
    }
  };

  try {
    const pdf = await html2pdf().set(opt).from(element).save();
    return pdf;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};