const TestResults = ({ 
  score, 
  totalQuestions, 
  passingScore, 
  onRetakeTest, 
  onReturnToDashboard, 
  darkMode,
  maxAttemptsReached,
  attempts 
}) => {
  const percentage = Math.round((score / totalQuestions) * 100);
  const passed = percentage >= passingScore;

  return (
    <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} p-6 rounded-lg shadow-lg`}>
      <h2 className="text-2xl font-bold mb-4">Test Results</h2>
      <p className="mb-2">Your Score: {score} / {totalQuestions}</p>
      <p className="mb-2">Percentage: {percentage}%</p>
      <p className={`text-lg font-semibold mb-2 ${passed ? (darkMode ? 'text-green-400' : 'text-green-600') : (darkMode ? 'text-red-400' : 'text-red-600')}`}>
        {passed ? 'Passed' : 'Failed'}
      </p>
      <p className="mb-2">Passing Score: {passingScore}%</p>
      <p className="mb-6">Attempts Used: {attempts}/2</p>
      
      <div className="flex space-x-4">
        {!passed && !maxAttemptsReached && (
          <button 
            onClick={onRetakeTest} 
            className={`px-4 py-2 rounded ${darkMode ? 'bg-yellow-600 hover:bg-yellow-700' : 'bg-yellow-500 hover:bg-yellow-600'} text-white`}
          >
            Retake Test
          </button>
        )}
        {maxAttemptsReached && !passed && (
          <p className={`${darkMode ? 'text-red-400' : 'text-red-600'} mb-4`}>
            Maximum attempts reached. Please contact SAP for assistance.
          </p>
        )}
      </div>
    </div>
  );
};

export default TestResults;