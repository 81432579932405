import React, { useEffect, useRef } from 'react';

const PipelineAnimation = () => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const { PI, cos, sin, abs, sqrt, pow, round, random, atan2 } = Math;
    const HALF_PI = 0.5 * PI;
    const TAU = 2 * PI;
    const TO_RAD = PI / 180;
    const rand = n => n * random();
    const fadeInOut = (t, m) => {
      let hm = 0.5 * m;
      return abs((t + hm) % m - hm) / (hm);
    };

    const branchCount = 50;
    const branchPropCount = 9;
    const branchPropsLength = branchCount * branchPropCount;
    const turnCount = 8;
    const turnAmount = (360 / turnCount) * TO_RAD;
    const turnChanceRange = 25;
    const baseSpeed = 1;
    const rangeSpeed = 0.7;
    const baseTTL = 300;
    const rangeTTL = 150;
    const baseWidth = 0.7;
    const rangeWidth = 0.7;
    const baseHue = 30;
    const rangeHue = 15;
    const backgroundColor = 'hsla(220,60%,1%,1)';

    let canvas, ctx, center, tick, branchProps;

    function setup() {
      createCanvas();
      resize();
      initBranches();
      draw();
    }

    function initBranches() {
      branchProps = new Float32Array(branchPropsLength);

      let i;

      for (i = 0; i < branchPropsLength; i += branchPropCount) {
        initBranch(i);
      }
    }

    function initBranch(i) {
      let x, y, direction, speed, life, ttl, width, hue, fadeState;

      x = rand(canvas.a.width);
      y = center[1];
      direction = (round(rand(1)) ? HALF_PI : TAU - HALF_PI);
      speed = baseSpeed + rand(rangeSpeed);
      life = 0;
      ttl = baseTTL + rand(rangeTTL);
      width = baseWidth + rand(rangeWidth);
      hue = baseHue + rand(rangeHue);
      fadeState = 0;

      branchProps.set([x, y, direction, speed, life, ttl, width, hue, fadeState], i);
    }

    function updateBranches() {
      tick++;

      let i;

      for (i = 0; i < branchPropsLength; i += branchPropCount) {
        updateBranch(i);
      }
    }

    function updateBranch(i) {
      let i2=1+i, i3=2+i, i4=3+i, i5=4+i, i6=5+i, i7=6+i, i8=7+i, i9=8+i;
      let x, y, direction, speed, life, ttl, width, hue, fadeState;

      x = branchProps[i];
      y = branchProps[i2];
      direction = branchProps[i3];
      speed = branchProps[i4];
      life = branchProps[i5];
      ttl = branchProps[i6]
      width = branchProps[i7];
      hue = branchProps[i8];
      fadeState = branchProps[i9];

      drawBranch(x, y, direction, speed, life, ttl, width, hue, fadeState);

      life++;
      x += cos(direction) * speed;
      y += sin(direction) * speed;
      
      if (life > ttl * 0.7) {
        fadeState = 1;
      }

      if (life > ttl) {
        initBranch(i);
      } else {
        let turnChance = !(tick % round(rand(turnChanceRange))) && (!(round(x) % 6) || !(round(y) % 6));
        let turnBias = round(rand(1)) ? -1 : 1;
        direction += turnChance ? turnAmount * turnBias : 0;

        branchProps[i] = x;
        branchProps[i2] = y;
        branchProps[i3] = direction;
        branchProps[i5] = life;
        branchProps[i9] = fadeState;

        checkBounds(x, y);
      }
    }

    function drawBranch(x, y, direction, speed, life, ttl, width, hue, fadeState) {
      const progress = life / ttl;
      let opacity = fadeState === 0 ? fadeInOut(life, ttl) : 1 - progress;
      opacity *= 0.125;
      
      ctx.a.save();
      ctx.a.strokeStyle = `hsla(${hue},100%,60%,${opacity})`;
      ctx.a.lineWidth = width;
      ctx.a.beginPath();
      ctx.a.moveTo(x, y);
      
      let segmentLength = speed * 3;
      let branchX = x;
      let branchY = y;
      for (let i = 0; i < 8; i++) {
        let angle = direction + (rand(0.5) - 0.25);
        branchX += cos(angle) * segmentLength;
        branchY += sin(angle) * segmentLength;
        ctx.a.lineTo(branchX, branchY);
        
        if (rand(1) < 0.3) {
          let offshootAngle = angle + (rand(2) - 1);
          let offshootLength = segmentLength * 0.5;
          ctx.a.moveTo(branchX, branchY);
          ctx.a.lineTo(
            branchX + cos(offshootAngle) * offshootLength,
            branchY + sin(offshootAngle) * offshootLength
          );
          ctx.a.moveTo(branchX, branchY);
        }
      }
      
      ctx.a.stroke();
      ctx.a.closePath();
      ctx.a.restore();
    }

    function checkBounds(x, y) {
      if (x > canvas.a.width) x = 0;
      if (x < 0) x = canvas.a.width;
      if (y > canvas.a.height) y = 0;
      if (y < 0) y = canvas.a.height;
    }

    function createCanvas() {
      canvas = {
        a: document.createElement('canvas'),
        b: document.createElement('canvas')
      };
      canvas.b.style = `
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `;
      containerRef.current.appendChild(canvas.b);
      ctx = {
        a: canvas.a.getContext('2d'),
        b: canvas.b.getContext('2d')
      };
      center = [];
      tick = 0;
    }

    function resize() {
      const { innerWidth, innerHeight } = window;
      
      canvas.a.width = innerWidth;
      canvas.a.height = innerHeight;

      ctx.a.drawImage(canvas.b, 0, 0);

      canvas.b.width = innerWidth;
      canvas.b.height = innerHeight;
      
      ctx.b.drawImage(canvas.a, 0, 0);

      center[0] = 0.5 * canvas.a.width;
      center[1] = 0.5 * canvas.a.height;
    }

    function render() {
      ctx.b.save();
      ctx.b.fillStyle = backgroundColor;
      ctx.b.fillRect(0,0,canvas.b.width,canvas.b.height);
      ctx.b.restore();

      ctx.b.save();
      ctx.b.filter = 'blur(8px)';
      ctx.b.drawImage(canvas.a, 0, 0);
      ctx.b.restore();

      ctx.b.save();
      ctx.b.drawImage(canvas.a, 0, 0);
      ctx.b.restore();
    }

    function draw() {
      updateBranches();
      render();
      window.requestAnimationFrame(draw);
    }

    setup();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
      if (containerRef.current) {
        containerRef.current.removeChild(canvas.b);
      }
    };
  }, []);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};
export default PipelineAnimation;