import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, ResponsiveContainer } from 'recharts';
import { Calendar, Zap, UserRoundPen, ClipboardPenLine, FileSearch} from 'lucide-react';
import keyPlan from '../assets/keyplan.jpg';
import FormattedDate from '../Layout/FormattedDate';
import PermitMonitoring from './PermitMonitoring';
import LotoDasboardRequests from './LotoDashboardRequests';

const data = [
  { name: 'Jan', value: 300 },
  { name: 'Feb', value: 200 },
  { name: 'Mar', value: 350 },
  { name: 'Apr', value: 400 },
  { name: 'May', value: 250 },
  { name: 'Jun', value: 300 },
];

const energisationRequests = [
  {
    id: "ER001",
    requester: "John Smith",
    asset: "Main Distribution Panel MDP-01",
    status: "Pending QA/QC",
    date: "2024-01-15",
    priority: "High"
  },
  {
    id: "ER002",
    requester: "Sarah Johnson",
    asset: "Transformer T-102",
    status: "Energised",
    date: "2024-01-14",
    priority: "Medium"
  },
  {
    id: "ER003",
    requester: "Mike Wilson",
    asset: "Emergency Generator EG-03",
    status: "Pending Cx",
    date: "2024-01-13",
    priority: "Low"
  },
  {
    id: "ER004",
    requester: "Emma Davis",
    asset: "UPS System UPS-02",
    status: "Pending",
    date: "2024-01-12",
    priority: "High"
  }
];

const StatCard = ({ title, value, change, icon }) => (
  <div className="bg-gray-800 rounded-lg p-4 flex flex-col">
    <div className="text-xs text-gray-400 mb-2">{title}</div>
    <div className="text-2xl font-bold mb-2">{value}</div>
    <div className={`text-xs ${change > 0 ? 'text-green-400' : 'text-red-400'}`}>
      {change > 0 ? '+' : ''}{change}%
    </div>
    <div className="mt-2 text-gray-400">{icon}</div>
  </div>
);

const CalendarItem = ({ time, title, person, color }) => (
  <div className="flex items-center mb-4">
    <div className={`w-2 h-2 rounded-full ${color} mr-2`}></div>
    <div className="flex-grow">
      <div className="text-sm">{title}</div>
      <div className="text-xs text-gray-400">{time} • {person}</div>
    </div>
    <button className="text-gray-400">•••</button>
  </div>
);

function Dashboard() {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();

  if (loading) {
    return <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">Loading...</div>;
  }

  if (!currentUser) {
    navigate('/login');
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold">Welcome, {currentUser.email}</h1>
        <p className="text-gray-400">Role: {currentUser.role}</p>
   
        
      </div>

      <div className="bg-gray-800 rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
        
          <div className="flex items-center space-x-4">
            <select className="bg-gray-700 rounded-full px-4 py-2">
              <option>Day 1</option>
              <option>Day 2</option>
              <option>Day 3</option>
              <option>Day 4</option>
              <option>Day 5</option>
              <option>Day 6</option>
              <option>Day 7</option>
            </select>
            <div className="bg-gray-700 rounded-full px-4 py-2 flex items-center">
              <Calendar className="w-4 h-4 mr-2" />
              <span><FormattedDate /></span>
            </div>
          </div>
        </div>
        <img src={keyPlan} alt="Logo" className="w-full mb-4" />
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2  p-4">
            <PermitMonitoring />
          </div>

          <div className="space-y-4">
            <div className="bg-gray-700 rounded-lg p-4">
              <LotoDasboardRequests />
            </div>
          
          </div>
        </div>

        <div className="mt-6">
          <div className="bg-gray-800 rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold text-white">Energisation Requests</h3>
              <div className="flex space-x-2">
                <button className="px-3 py-1 text-sm bg-gray-700 rounded-lg hover:bg-gray-600">Filter</button>
                <button className="px-3 py-1 text-sm bg-emerald-600 rounded-lg hover:bg-emerald-500">See All Request</button>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead className="bg-gray-700">
                  <tr>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">ID</th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Requester</th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Asset</th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Status</th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Date</th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Priority</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                  {energisationRequests.map((request) => (
                    <tr key={request.id} className="hover:bg-gray-700">
                      <td className="px-4 py-3 text-sm">{request.id}</td>
                      <td className="px-4 py-3 text-sm">{request.requester}</td>
                      <td className="px-4 py-3 text-sm">{request.asset}</td>
                      <td className="px-4 py-3 text-sm">
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          request.status === 'Approved' ? 'bg-green-900 text-green-300' :
                          request.status === 'Pending' ? 'bg-yellow-900 text-yellow-300' :
                          request.status === 'Energised' ? 'bg-green-900 text-yellow-300' :
                          'bg-blue-900 text-blue-300'
                        }`}>
                          {request.status}
                        </span>
                      </td>
                      <td className="px-4 py-3 text-sm">{request.date}</td>
                      <td className="px-4 py-3 text-sm">
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          request.priority === 'High' ? 'bg-red-900 text-red-300' :
                          request.priority === 'Medium' ? 'bg-orange-900 text-orange-300' :
                          'bg-green-900 text-green-300'
                        }`}>
                          {request.priority}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
          
        
         
        </div>
       

       
      </div>
    </div>
  );
}

function AdminDashboard() {
  return <div className="text-lg text-gray-300">Admin Dashboard Content</div>;
}

function APDashboard() {
  return <div className="text-lg text-gray-300">AP Dashboard Content</div>;
}

function SAPDashboard() {
  return <div className="text-lg text-gray-300">SAP Dashboard Content</div>;
}

export default Dashboard;
