import React, { useState } from 'react';
import { Form, Input, Select, DatePicker, Radio, Button, message } from 'antd';
import SearchableAssetInput from './SeachableAssetInput';

import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';

const { Option } = Select;

const EnergisationRequestForm = () => {
  const [form] = Form.useForm();
  const [otherVoltage, setOtherVoltage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formattedValues = {
        ...values,
        requestedPowerOnDate: values.requestedPowerOnDate.toDate(),
        assetToEnergise: selectedAssets.map(asset => asset.Asset),
        status: 'PENDING'
      };

      const docRef = await addDoc(collection(db, 'EnergisationRequest'), formattedValues);
      
      console.log('Energisation request submitted successfully with ID:', docRef.id);
      message.success('Energisation request submitted successfully');
      form.resetFields();
    } catch (error) {
      console.error('Error submitting energisation request:', error);
      message.error('Failed to submit energisation request. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVoltageChange = (value) => {
    setOtherVoltage(value === 'Other');
  };

  return (
    <div className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold text-white mb-6">Energisation Request Form</h2>
      <Form form={form} onFinish={onFinish} layout="vertical" className="space-y-6">
        <Form.Item name="name" label={<span className="text-gray-300">Name</span>} rules={[{ required: true, message: 'Please input your name!' }]}>
          <Input className="bg-gray-800 text-white border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-md focus:bg-gray-700" />
        </Form.Item>

        <Form.Item name="email" label={<span className="text-gray-300">Requestor Email Address</span>} rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}>
          <Input className="bg-gray-800 text-black border-gray-700 focus:border-black-500 focus:ring-1 focus:ring-blue-500 rounded-md focus:bg-gray-700" />
        </Form.Item>

        <Form.Item name="company" label={<span className="text-gray-300">Company</span>} rules={[{ required: true, message: 'Please input your company!' }]}>
          <Input className="bg-gray-800 text-white border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-md" />
        </Form.Item>

        <Form.Item name="voltageRange" label={<span className="text-gray-300">Voltage Range of Equipment</span>} rules={[{ required: true, message: 'Please select voltage range!' }]}>
          <Select onChange={handleVoltageChange} className="bg-gray-800 text-white border-gray-700 rounded-md focus:bg-gray-700">
            <Option value="110VDC">Control Voltage - 110VDC</Option>
            <Option value="240-400VAC">Low Voltage - 240 ~ 400 VAC</Option>
            <Option value="10kV">Medium Voltage - 10kV</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>

        {otherVoltage && (
          <Form.Item name="otherVoltage" label={<span className="text-gray-300">Please enter asset tag here</span>} rules={[{ required: true, message: 'Please enter the asset tag!' }]}>
            <Input className="bg-gray-800 text-white border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-md" />
          </Form.Item>
        )}

        <Form.Item label={<span className="text-gray-300">Asset to be energised</span>}>
          <SearchableAssetInput
            value={selectedAssets}
            onChange={setSelectedAssets}
          />
        </Form.Item>

        <Form.Item name="location" label={<span className="text-gray-300">Location of asset to be energised - (building, floor, room)</span>} rules={[{ required: true, message: 'Please input the location!' }]}>
          <Input className="bg-gray-800 text-black border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-md" />
        </Form.Item>

        <Form.Item 
          name="sourceAssetTag" 
          label={<span className="text-gray-300">Source Asset Tag - NB - Energisation Request will be rejected if source not specified</span>}
          rules={[{ required: true, message: 'Please input the source asset tag!' }]}
        >
          <Input className="bg-gray-800 text-black border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 rounded-md" />
        </Form.Item>

        <Form.Item name="requestedPowerOnDate" label={<span className="text-gray-300">Requested Power on date:*</span>} rules={[{ required: true, message: 'Please select a date!' }]}>
          <DatePicker className="bg-gray-800 text-white border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 w-full rounded-md focus:bg-gray-700" />
        </Form.Item>

        <Form.Item name="energisationType" label={<span className="text-gray-300">Select energisation type</span>} rules={[{ required: true, message: 'Please select energisation type!' }]}>
          <Radio.Group className="text-white space-x-4">
            <Radio value="temporary" className="text-white">
              <span className="ml-2">Temporary energisation</span>
            </Radio>
            <Radio value="permanent" className="text-white">
              <span className="ml-2">Permanent energisation</span>
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            loading={loading} 
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full transition duration-300 ease-in-out transform hover:scale-105"
          >
            Submit Request
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EnergisationRequestForm;
