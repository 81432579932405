import HVMVSchematic from '../assets/HVMVMimic.jpg';
import React, { useState, useRef } from 'react';
import { Pencil, Circle, Cable, Hand, Save, ZoomIn, ZoomOut, Undo } from 'lucide-react';
import { useDrawing } from './MimicDrawing/UseDrawing';
import { DrawingSurface } from './MimicDrawing/DrawingSurface';

const Mimic = () => {
  const [scale, setScale] = useState(1);
  const [selectedColor, setSelectedColor] = useState('#ff0000');
  const [selectedWidth, setSelectedWidth] = useState(2);
  const [mode, setMode] = useState('select'); // 'select', 'board', 'breaker', 'circuit'
  const [breakers, setBreakers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [circuits, setCircuits] = useState([]);
  const [showNameDialog, setShowNameDialog] = useState(false);

  const svgRef = useRef(null);
  const containerRef = useRef(null);

  const {
    isDrawing,
    currentLine,
    lines,
    startPoint,
    showGuides,
    startDrawing,
    draw,
    endDrawing,
    undoLastLine,
    setLines
  } = useDrawing(scale);

  const handleLineEndClick = (point, rotation) => {
    if (mode === 'breaker') {
      setBreakers(prev => [...prev, { 
        id: `breaker-${Date.now()}`,
        x: point.x, 
        y: point.y, 
        rotation,
        isOpen: true,
        connections: {
          top: null,
          bottom: null
        }
      }]);
    }
  };

  const handleSave = () => {
    if (lines.length > 0) {
      setShowNameDialog(true);
    }
  };

  const handleNameSubmit = (name) => {
    if (!name.trim()) return;

    const newElement = {
      id: `${mode}-${Date.now()}`,
      name: name.trim(),
      lines: [...lines],
      color: selectedColor
    };

    if (mode === 'board') {
      setBoards(prev => [...prev, newElement]);
    } else if (mode === 'circuit') {
      setCircuits(prev => [...prev, newElement]);
    }

    setLines([]);
    setShowNameDialog(false);
  };

  const toggleBreakerState = (index) => {
    setBreakers(prev => prev.map((breaker, i) => 
      i === index ? { ...breaker, isOpen: !breaker.isOpen } : breaker
    ));
  };

  const handleZoom = (delta) => {
    setScale(prevScale => {
      const newScale = prevScale + delta;
      return newScale > 0.1 ? newScale : 0.1;
    });
  };

  const undoLastBreaker = () => {
    setBreakers(prev => prev.slice(0, -1));
  };

  return (
    <div className="w-full h-full relative overflow-hidden border border-gray-300">
      <div className="absolute left-4 top-1/2 -translate-y-1/2 flex flex-col gap-4 bg-white p-4 rounded-lg shadow-lg z-10">
        {/* Zoom Controls */}
        <div className="flex flex-col items-center gap-2 pb-4 border-b border-gray-200">
          <button 
            onClick={() => handleZoom(0.1)}
            className="w-10 h-10 bg-blue-500 text-white rounded-lg flex items-center justify-center hover:bg-blue-600 transition-colors"
          >
            <ZoomIn size={20} />
          </button>
          <button 
            onClick={() => handleZoom(-0.1)}
            className="w-10 h-10 bg-blue-500 text-white rounded-lg flex items-center justify-center hover:bg-blue-600 transition-colors"
          >
            <ZoomOut size={20} />
          </button>
        </div>

        {/* Mode Selection */}
        <div className="flex flex-col items-center gap-2 pb-4 border-b border-gray-200">
          <button
            onClick={() => setMode('select')}
            className={`w-10 h-10 rounded-lg flex items-center justify-center transition-colors
              ${mode === 'select' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            title="Select Mode"
          >
            <Hand size={20} />
          </button>
          <button
            onClick={() => setMode('board')}
            className={`w-10 h-10 rounded-lg flex items-center justify-center transition-colors
              ${mode === 'board' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            title="Board Mode"
          >
            <Pencil size={20} />
          </button>
          <button
            onClick={() => setMode('breaker')}
            className={`w-10 h-10 rounded-lg flex items-center justify-center transition-colors
              ${mode === 'breaker' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            title="Breaker Mode"
          >
            <Circle size={20} />
          </button>
          <button
            onClick={() => setMode('circuit')}
            className={`w-10 h-10 rounded-lg flex items-center justify-center transition-colors
              ${mode === 'circuit' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            title="Circuit Mode"
          >
            <Cable size={20} />
          </button>
        </div>

        {/* Drawing Controls */}
        <div className="flex flex-col items-center gap-2">
          <input 
            type="color" 
            value={selectedColor}
            onChange={(e) => setSelectedColor(e.target.value)}
            className="w-10 h-10 p-1 cursor-pointer rounded"
          />
          <select 
            value={selectedWidth}
            onChange={(e) => setSelectedWidth(Number(e.target.value))}
            className="w-full px-2 py-1 rounded border border-gray-300"
          >
            <option value="1">Thin</option>
            <option value="2">Medium</option>
            <option value="4">Thick</option>
          </select>
        </div>

        {/* Save Button - Only show when there are lines and in board/circuit mode */}
        {lines.length > 0 && (mode === 'board' || mode === 'circuit') && (
          <button
            onClick={handleSave}
            className="w-full bg-green-500 text-white px-3 py-2 rounded-lg flex items-center justify-center gap-2 hover:bg-green-600 transition-colors"
          >
            <Save size={16} />
            Save {mode === 'board' ? 'Board' : 'Circuit'}
          </button>
        )}

        {/* Undo Button */}
        <div className="pt-4 border-t border-gray-200">
          <button 
            onClick={mode === 'breaker' ? undoLastBreaker : undoLastLine}
            className="w-full bg-red-500 text-white px-3 py-2 rounded-lg flex items-center justify-center gap-2"
          >
            <Undo size={16} />
            Undo
          </button>
        </div>
      </div>

      {/* Drawing Surface */}
      <div ref={containerRef} className="relative w-full h-full overflow-auto pl-24">
        <div style={{ 
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          width: '5520px',
          height: '2003px',
          position: 'relative'
        }}>
          <img
            src={HVMVSchematic}
            alt="Schematic Background"
            className="absolute top-0 left-0 w-full h-full object-contain"
          />
          
          <DrawingSurface 
            svgRef={svgRef}
            width={5520}
            height={2003}
            showGuides={showGuides}
            startPoint={startPoint}
            lines={lines}
            currentLine={currentLine}
            breakers={breakers}
            boards={boards}
            circuits={circuits}
            mode={mode}
            onMouseDown={(e) => (mode === 'board' || mode === 'circuit') && 
              startDrawing(e, svgRef, selectedColor, selectedWidth)}
            onMouseMove={(e) => (mode === 'board' || mode === 'circuit') && 
              draw(e, svgRef)}
            onMouseUp={endDrawing}
            onMouseLeave={endDrawing}
            onLineEndClick={handleLineEndClick}
            onBreakerClick={toggleBreakerState}
          />
        </div>
      </div>

      {/* Name Dialog */}
      {showNameDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-lg font-semibold mb-4">
              Name your {mode === 'board' ? 'board' : 'circuit'}
            </h3>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded mb-4"
              placeholder={`Enter ${mode === 'board' ? 'board' : 'circuit'} name`}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleNameSubmit(e.target.value);
                }
              }}
              autoFocus
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowNameDialog(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
              >
                Cancel
              </button>
              <button
                onClick={(e) => handleNameSubmit(e.target.previousElementSibling.value)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mimic;