import React, { useState } from 'react';
import useCurrentUser from '../PermitsStatus/hooks/useCurrentUser';
import { useAuth } from '../../../contexts/AuthContext';

const ClearancePermitModal = ({ isOpen, onClose, onClearance, permitData }) => {
  const { currentUser } = useAuth();
  const { userData, loading } = useCurrentUser();
  const [formData, setFormData] = useState({
    remarks: '',
  });

  if (!isOpen || !permitData) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    const clearanceData = {
      permitId: permitData.id,
      clearanceDetails: {
        clearedBy: {
          userId: currentUser?.uid,
          name: userData?.name || '',
          phoneNumber: userData?.phoneNumber || '',
          timestamp: new Date().toISOString()
        },
        currentHolder: {
          name: permitData.issuedDetails?.issuedTo?.name || '',
          contactNumber: permitData.issuedDetails?.issuedTo?.contactNumber || '',
          company: permitData.issuedDetails?.issuedTo?.company || ''
        },
        remarks: formData.remarks || ''
      }
    };
    
    console.log('ClearancePermitModal - Submitting clearance data:', clearanceData);
    onClearance(clearanceData);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={handleOverlayClick}
    >
      <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-md border border-gray-700 flex flex-col max-h-[90vh]">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            Clear Permit
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-4 space-y-6">
          {/* Clearance Officer Details */}
          

          {/* Current Permit Holder */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100 border-b border-gray-700 pb-2">
              Current Permit Holder
            </h3>
            <div className="space-y-3">
              <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
                <p className="text-sm text-gray-300">Name</p>
                <p className="text-gray-100">{permitData.issuedDetails?.issuedTo?.name || 'N/A'}</p>
              </div>
              <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
                <p className="text-sm text-gray-300">Contact Number</p>
                <p className="text-gray-100">{permitData.issuedDetails?.issuedTo?.contactNumber || 'N/A'}</p>
              </div>
              <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
                <p className="text-sm text-gray-300">Company</p>
                <p className="text-gray-100">{permitData.issuedDetails?.issuedTo?.company || 'N/A'}</p>
              </div>
            </div>
          </div>

          {/* Remarks */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100 border-b border-gray-700 pb-2">
              Clearance Remarks
            </h3>
            <textarea
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
              className="w-full h-24 bg-gray-800 text-gray-200 border border-gray-700 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              placeholder="Enter any clearance remarks..."
            />
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-2 p-4 border-t border-gray-700 bg-gray-900">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-200 bg-gray-700 border border-gray-600 rounded-md hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Clear Permit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClearancePermitModal;