import React from 'react';
import Pagination from './Pagination';

const AssetSidebar = ({ 
  assets, 
  selectedAsset, 
  onAssetClick, 
  searchTerm, 
  onSearchChange, 
  currentPage, 
  assetsPerPage, 
  onPageChange,
  filterAttribute,
  onFilterAttributeChange,
  filterValue,
  onFilterValueChange
}) => {
  const filterOptions = ['Type', 'CampusLocation', 'Zone/Day'];
  
  const uniqueFilterValues = [...new Set(assets.map(asset => asset[filterAttribute]))].filter(Boolean);

  const filteredAssets = assets.filter(asset => 
    asset.Asset && 
    asset.Asset.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (!filterValue || asset[filterAttribute] === filterValue)
  );

  const indexOfLastAsset = currentPage * assetsPerPage;
  const indexOfFirstAsset = indexOfLastAsset - assetsPerPage;
  const currentAssets = filteredAssets.slice(indexOfFirstAsset, indexOfLastAsset);

  return (
    <div className="bg-gray-800 p-4 h-full flex flex-col">
      <h2 className="text-xl font-bold mb-4 text-white">Assets</h2>
      
      <div className="mb-4 flex space-x-2">
        <select 
          value={filterAttribute} 
          onChange={(e) => onFilterAttributeChange(e.target.value)}
          className="bg-gray-700 text-white rounded px-2 py-1 flex-grow"
        >
          <option value="">Filter by...</option>
          {filterOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        
        {filterAttribute && (
          <select 
            value={filterValue} 
            onChange={(e) => onFilterValueChange(e.target.value)}
            className="bg-gray-700 text-white rounded px-2 py-1 flex-grow"
          >
            <option value="">All</option>
            {uniqueFilterValues.map(value => (
              <option key={value} value={value}>{value}</option>
            ))}
          </select>
        )}
      </div>
      
      <input
        type="text"
        placeholder="Search assets..."
        value={searchTerm}
        onChange={onSearchChange}
        className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
      />
      
      <div className="flex-grow overflow-auto">
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left text-gray-400">Asset</th>
            </tr>
          </thead>
          <tbody>
            {currentAssets.map((asset) => (
              <tr
                key={asset.id}
                className={`cursor-pointer hover:bg-gray-700 ${selectedAsset && selectedAsset.id === asset.id ? 'bg-gray-600' : ''}`}
                onClick={() => onAssetClick(asset)}
              >
                <td className="py-2 text-white">{asset.Asset || 'No Asset'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(filteredAssets.length / assetsPerPage)}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default AssetSidebar;
