import React, { useState, useEffect } from 'react';

const EPTWForm = ({ register, permitData }) => {
  const [isICCCompleted, setIsICCCompleted] = useState(!!permitData?.attachedICC);

  useEffect(() => {
    setIsICCCompleted(!!permitData?.attachedICC);
  }, [permitData?.attachedICC]);

  const handleICCCheckboxChange = (e) => {
    setIsICCCompleted(e.target.checked);
    if (!e.target.checked) {
      const event = {
        target: {
          name: 'attachedICC',
          value: ''
        }
      };
      register('attachedICC').onChange(event);
    }
  };
  return (
    <div className="space-y-6">
      <div className="bg-gray-900 p-6 rounded-xl shadow-lg border border-gray-700">
        <div className="grid gap-6">
          {/* Section 1: Basic Information */}
          <div>
            <h3 className="text-lg font-medium text-gray-200 mb-4">Section 1: Basic Information</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">(I) Location</label>
                <input
                  {...register('location')}
                  placeholder="Enter location details"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">(II)Equipment Identification</label>
                <input
                  {...register('equipmentIdentification')}
                  placeholder="Enter equipment ID or description"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">(III)Work To Be Done</label>
                <textarea
                  {...register('workToBeDone')}
                  placeholder="Describe the work in detail"
                  rows="4"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                />
              </div>
            </div>
          </div>

          {/* Section 2: Safety Controls */}
          <div>
            <h3 className="text-lg font-medium text-gray-200 mb-4">Section 2: Safety Controls</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">(I)Points of Isolation</label>
                <textarea
                  {...register('pointsOfIsolation')}
                  placeholder="List all isolation points"
                  rows="3"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">(II)Primary Earthing Device(s)</label>
                <textarea
                  {...register('primaryEarthingDevices')}
                  placeholder="Specify earthing devices and their locations"
                  rows="3"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">(III)Actions Taken to Avoid Danger</label>
                <textarea
                  {...register('actionsToAvoidDanger')}
                  placeholder="Detail actions "
                  rows="4"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">(IV)Further Precautions During Work</label>
                <textarea
                  {...register('furtherPrecautions')}
                  placeholder="List precautions to avoid system derived hazards during work"
                  rows="4"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">(V)Variable Precautions</label>
                <textarea
                  {...register('variablePrecautions')}
                  placeholder="List precautions that may be varied (approved procedure required)"
                  rows="4"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                />
              </div>

              <div className="space-y-3">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="iccCompleted"
                    checked={isICCCompleted}
                    onChange={handleICCCheckboxChange}
                    className="w-4 h-4 text-blue-600 bg-gray-800 border-gray-600 rounded focus:ring-blue-500"
                  />
                  <label htmlFor="iccCompleted" className="ml-2 text-sm font-medium text-gray-300">
                    (VI) Has ICC been completed?
                  </label>
                </div>
                
                {isICCCompleted && (
                  <div>
                    <textarea
                      {...register('attachedICC')}
                      placeholder="Enter ICC details"
                      rows="3"
                      className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default EPTWForm;
