import React from 'react';
import { FaFileAlt, FaBolt, FaCog } from 'react-icons/fa';

const PermitTypesTabs = ({ activePermitType, setActivePermitType }) => {
  const permitTypes = [
    { type: 'LOA', icon: FaFileAlt, color: 'blue' },
    { type: 'EPTW', icon: FaBolt, color: 'red' },
    { type: 'MPTW', icon: FaCog, color: 'gray' },
  ];

  return (
    <div className="flex mb-4 bg-gray-800 rounded-lg overflow-hidden">
      {permitTypes.map(({ type, icon: Icon, color }) => (
        <button
          key={type}
          className={`px-4 py-2 flex-1 flex items-center justify-center ${
            activePermitType === type ? `bg-${color}-600 text-white` : 'text-gray-300 hover:bg-gray-700'
          } transition-colors duration-200`}
          onClick={() => setActivePermitType(type)}
        >
          <Icon className="mr-2" />
          {type}
        </button>
      ))}
    </div>
  );
};

export default PermitTypesTabs;
