import React, { useState } from 'react';
import { PieChart, BarChart, Plus } from 'lucide-react';
import NewMonitoringModal from './NewMonitoringModal';
import NewObservationModal from './NewObservationModal';

const Audits = () => {
  const [activeTab, setActiveTab] = useState('monitoring');
  const [isNewMonitoringOpen, setIsNewMonitoringOpen] = useState(false);
  const [isNewObservationOpen, setIsNewObservationOpen] = useState(false);

  const monitoringData = [
    { id: 1, type: 'Safety Check', status: 'In Progress', issuesRaised: 3, issuesRectified: 1, date: '2024-12-04' },
    { id: 2, type: 'Equipment Inspection', status: 'Completed', issuesRaised: 2, issuesRectified: 2, date: '2024-12-03' }
  ];

  const observationsData = [
    { id: 1, type: 'Behavioral', status: 'New', reporter: 'John Doe', date: '2024-12-04' },
    { id: 2, type: 'Environmental', status: 'Reviewed', reporter: 'Jane Smith', date: '2024-12-03' }
  ];

  const stats = {
    monitoring: {
      total: 10,
      completed: 7,
      pending: 3,
      issuesRaised: 5,
      issuesRectified: 5
    },
    observations: {
      total: 8,
      behavioral: 3,
      environmental: 3,
      procedural: 2
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-6 space-y-6">
      {/* Header */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0 mb-6">
        <h1 className="text-2xl font-bold text-white">Audits Dashboard</h1>
        <div className="flex space-x-3 w-full md:w-auto">
          <button 
            onClick={() => setIsNewMonitoringOpen(true)}
            className="flex-1 md:flex-none bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-2.5 rounded-lg flex items-center justify-center transition-colors duration-200"
          >
            <Plus className="mr-2 h-4 w-4" />
            New Monitoring
          </button>
          <button 
            onClick={() => setIsNewObservationOpen(true)}
            className="flex-1 md:flex-none bg-blue-600 hover:bg-blue-700 text-white px-4 py-2.5 rounded-lg flex items-center justify-center transition-colors duration-200"
          >
            <Plus className="mr-2 h-4 w-4" />
            New Observation
          </button>
        </div>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Monitoring Stats */}
        <div className="bg-gray-800 rounded-xl shadow-lg p-6">
          <div className="flex items-center mb-4">
            <PieChart className="mr-2 h-5 w-5 text-emerald-400" />
            <h2 className="text-xl font-semibold text-white">Active Monitoring Stats</h2>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-center p-4 bg-gray-700/50 rounded-lg">
              <div className="text-2xl font-bold text-emerald-400">{stats.monitoring.completed}</div>
              <div className="text-gray-300">Completed</div>
            </div>
            <div className="text-center p-4 bg-gray-700/50 rounded-lg">
              <div className="text-2xl font-bold text-yellow-400">{stats.monitoring.pending}</div>
              <div className="text-gray-300">Pending</div>
            </div>
            <div className="text-center p-4 bg-gray-700/50 rounded-lg">
              <div className="text-2xl font-bold text-blue-400">{stats.monitoring.issuesRaised}</div>
              <div className="text-gray-300">Issues Raised</div>
            </div>
            <div className="text-center p-4 bg-gray-700/50 rounded-lg">
              <div className="text-2xl font-bold text-purple-400">{stats.monitoring.issuesRectified}</div>
              <div className="text-gray-300">Issues Rectified</div>
            </div>
          </div>
        </div>

        {/* Observations Stats */}
        <div className="bg-gray-800 rounded-xl shadow-lg p-6">
          <div className="flex items-center mb-4">
            <BarChart className="mr-2 h-5 w-5 text-blue-400" />
            <h2 className="text-xl font-semibold text-white">Safety Observations Stats</h2>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-center p-4 bg-gray-700/50 rounded-lg">
              <div className="text-2xl font-bold text-blue-400">{stats.observations.behavioral}</div>
              <div className="text-gray-300">Behavioral</div>
            </div>
            <div className="text-center p-4 bg-gray-700/50 rounded-lg">
              <div className="text-2xl font-bold text-green-400">{stats.observations.environmental}</div>
              <div className="text-gray-300">Environmental</div>
            </div>
            <div className="text-center p-4 bg-gray-700/50 rounded-lg">
              <div className="text-2xl font-bold text-yellow-400">{stats.observations.procedural}</div>
              <div className="text-gray-300">Procedural</div>
            </div>
            <div className="text-center p-4 bg-gray-700/50 rounded-lg">
              <div className="text-2xl font-bold text-purple-400">{stats.observations.total}</div>
              <div className="text-gray-300">Total</div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="w-full">
        <div className="border-b border-gray-700">
          <div className="flex space-x-8">
            <button
              onClick={() => setActiveTab('monitoring')}
              className={`py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200 ${
                activeTab === 'monitoring'
                  ? 'border-emerald-500 text-emerald-400'
                  : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'
              }`}
            >
              Active Monitoring
            </button>
            <button
              onClick={() => setActiveTab('observations')}
              className={`py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200 ${
                activeTab === 'observations'
                  ? 'border-blue-500 text-blue-400'
                  : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'
              }`}
            >
              Safety Observations
            </button>
          </div>
        </div>

        {/* Tab Content */}
        <div className="mt-6">
          {activeTab === 'monitoring' ? (
            <div className="bg-gray-800 rounded-xl shadow-lg overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="bg-gray-700/50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Type</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Status</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Issues Raised</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Issues Rectified</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Date</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {monitoringData.map((item) => (
                      <tr key={item.id} className="hover:bg-gray-700/50 transition-colors duration-150">
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{item.type}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            item.status === 'Completed' ? 'bg-emerald-900/50 text-emerald-400' : 'bg-yellow-900/50 text-yellow-400'
                          }`}>
                            {item.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{item.issuesRaised}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{item.issuesRectified}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{item.date}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <button className="text-blue-400 hover:text-blue-300 transition-colors duration-200">View</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="bg-gray-800 rounded-xl shadow-lg overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="bg-gray-700/50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Type</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Status</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Reporter</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Date</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {observationsData.map((item) => (
                      <tr key={item.id} className="hover:bg-gray-700/50 transition-colors duration-150">
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{item.type}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            item.status === 'Reviewed' ? 'bg-emerald-900/50 text-emerald-400' : 'bg-blue-900/50 text-blue-400'
                          }`}>
                            {item.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{item.reporter}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">{item.date}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <button className="text-blue-400 hover:text-blue-300 transition-colors duration-200">View</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <NewMonitoringModal 
        isOpen={isNewMonitoringOpen}
        onClose={() => setIsNewMonitoringOpen(false)}
      />
      <NewObservationModal
        isOpen={isNewObservationOpen}
        onClose={() => setIsNewObservationOpen(false)}
      />
    </div>
  );
};

export default Audits;
