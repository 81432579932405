import React, { useState } from 'react';
import { Routes, Route, Navigate,useLocation } from 'react-router-dom';
import Navbar from './components/Layout/Navbar';
import Sidebar from './components/Layout/Sidebar';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard/Dashboard';
import Permits from './components/Permits/Permits';
import PermitRequests from './components/Permits/PermitRequests';
import PrivateRoute from './components/PrivateRoute';
import AssetRegister from './components/AssetRegister/AssetRegister';
import Audits from './components/Audits/Audits';
import Mimic from './components/Mimic/Mimic';
import CPRequestForm from './components/CP/CPRequestForm';
import CPTest from './components/CP/CPTest';
import Etracker from './components/Etracker/Etracker';
import CPRegister from './components/CPRegister/CPRegister';
import Account from './components/Account';


function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="App">
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cp-request" element={<CPRequestForm />} />
        <Route 
          path="/cptest" 
          element={
            <PublicTestRoute>
              <CPTest />
            </PublicTestRoute>
          } 
        />

        {/* Protected Routes with Layout */}
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <div className="flex h-screen">
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <div className="flex flex-col flex-1">
                  <Navbar />
                  <main className="flex-1 bg-gray-100 p-4">
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/permits" element={<Permits />} />
                      <Route path="/permit-requests" element={<PermitRequests />} />
                      <Route path="/assetregister" element={<AssetRegister />} />
                      <Route path="/audits" element={<Audits />} />
                      <Route path="/mimic" element={<Mimic />} />
                      <Route path="/etracker" element={<Etracker />} />
                      <Route path="/cp-register" element={<CPRegister />} />
                      <Route path="/account" element={<Account />} />
                  
                      <Route path="/" element={<Navigate to="/dashboard" replace />} />
                    </Routes>
                  </main>
                </div>
              </div>
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

// New component to protect the CPTest route
const PublicTestRoute = ({ children }) => {
  const location = useLocation();
  
  // Check if we have the necessary state from the form submission
  const hasValidState = location.state && 
    location.state.registrationId && 
    location.state.email && 
    location.state.isExternalUser;

  if (!hasValidState) {
    // Redirect to the request form if accessed directly
    return <Navigate to="/cp-request" replace />;
  }

  return children;
};

export default App;
