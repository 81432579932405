import React, { useState, useEffect } from 'react';
import { permitStatusHelper } from './utils/permitStatusHelper';
import PermitActions from './PermitActions';
import PermitService from './services/PermitService';

const PermitList = ({ 
  permits, 
  handleViewPermit, 
  handleIssuePermit, 
  handleClosePermit,
  handleTransferPermit,
  handleClearancePermit,
  handleEditPermit
}) => {
  const { getStatusColor } = permitStatusHelper;
  const [userCache, setUserCache] = useState({});
  
  const fetchUserData = async (userId) => {
    if (userCache[userId]) {
      return userCache[userId];
    }

    try {
      const userData = await PermitService.getUserData(userId);
      if (userData) {
        setUserCache(prev => ({
          ...prev,
          [userId]: userData
        }));
        return userData;
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
    return null;
  };

  // State to store prepared by names
  const [preparedByNames, setPreparedByNames] = useState({});

  // Fetch user names when permits change
  useEffect(() => {
    const loadUserNames = async () => {
      const names = {};
      for (const permit of permits) {
        if (permit.preparedBy?.userId && !preparedByNames[permit.preparedBy.userId]) {
          const userData = await fetchUserData(permit.preparedBy.userId);
          if (userData) {
            names[permit.preparedBy.userId] = userData.name;
          }
        }
      }
      setPreparedByNames(prev => ({ ...prev, ...names }));
    };

    loadUserNames();
  }, [permits]);
  if (!permits || permits.length === 0) {
    return <div className="text-gray-400 text-center py-8">No permits found</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-800">
            <th className="border border-gray-700 p-2">Number</th>
            <th className="border border-gray-700 p-2">Prepared By</th>
            <th className="border border-gray-700 p-2">Current Holder</th>
            <th className="border border-gray-700 p-2">Location</th>
            <th className="border border-gray-700 p-2">Work to be Done</th>
            <th className="border border-gray-700 p-2">Date Prepared</th>
            <th className="border border-gray-700 p-2">Status</th>
            <th className="border border-gray-700 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {permits.map((permit) => (
            <tr key={permit.id} className="hover:bg-gray-800 transition-colors duration-200">
              <td className="border border-gray-700 p-2">{permit.number}</td>
              <td className="border border-gray-700 p-2">
                {preparedByNames[permit.preparedBy?.userId] || 'Loading...'}
              </td>
              <td className="border border-gray-700 p-2">
                {permit.issuedDetails?.issuedTo ? (
                  <div>
                    <div>{permit.issuedDetails.issuedTo.name}</div>
                    <div className="text-sm text-gray-400">{permit.issuedDetails.issuedTo.company}</div>
                  </div>
                ) : (
                  'Not Issued'
                )}
              </td>
              <td className="border border-gray-700 p-2">{permit.location}</td>
              <td className="border border-gray-700 p-2">{permit.workToBeDone}</td>
              <td className="border border-gray-700 p-2">
                {permit.createdAt?.toDate().toLocaleString()}
              </td>
              <td className="border border-gray-700 p-2">
                <span className={`px-2 py-1 rounded ${getStatusColor(permit.status)}`}>
                  {permit.status}
                </span>
              </td>
              <td className="border border-gray-700 p-2">
                <PermitActions
                  permit={permit}
                  handleViewPermit={handleViewPermit}
                  handleIssuePermit={handleIssuePermit}
                  handleClosePermit={handleClosePermit}
                  handleTransferPermit={handleTransferPermit}
                  handleClearancePermit={handleClearancePermit}
                  handleEditPermit={handleEditPermit}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PermitList;
