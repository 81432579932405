import { db } from '../../../services/firebase';
import { doc, runTransaction } from 'firebase/firestore';

export function usePermitNumber() {
  const formatPermitNumber = (number) => {
    if (number < 10) return `00${number}`;
    if (number < 100) return `0${number}`;
    return number.toString();
  };

  const getNextNumber = async (permitType) => {
    try {
      const permitTypeRef = doc(db, 'permitTypes', permitType);
      
      const newNumber = await runTransaction(db, async (transaction) => {
        const permitTypeDoc = await transaction.get(permitTypeRef);
        
        if (!permitTypeDoc.exists()) {
          throw new Error(`Permit type ${permitType} not found`);
        }

        const currentNumber = permitTypeDoc.data().currentNumber;
        const nextNumber = currentNumber + 1;
        
        transaction.update(permitTypeRef, { currentNumber: nextNumber });
        
        return `${formatPermitNumber(nextNumber)}`;
      });

      return newNumber;
    } catch (error) {
      console.error('Error generating permit number:', error);
      throw error;
    }
  };

  return { getNextNumber };
}