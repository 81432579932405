import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const generatePDF = async (request) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  
  // Add title
  doc.setFontSize(22);
  doc.setFont('helvetica', 'bold');
  doc.text('Energisation Request', pageWidth / 2, 20, { align: 'center' });
  
  // Add date
  doc.setFontSize(12);
  doc.setFont('helvetica', 'normal');
  doc.text(`Date of Request: ${request.date}`, 20, 35);

  // Section 1: Energisation Request Information
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text('Section 1: Energisation Request Information', 20, 45);
  
  // Create two-column layout for information
  doc.setFontSize(11);
  doc.setFont('helvetica', 'normal');
  
  const leftColumn = [
    [`Energisation Type: ${request.energisationType}`, 60],
    [`Requestor: ${request.name}`, 70],
    [`Company: ${request.company}`, 80],
    [`Equipment: ${request.equipTag}`, 90],
    [`Source: ${request.sourceTag}`, 100],
    [`Location: ${request.location}`, 110]
  ];

  const rightColumn = [
    [`Cx ACC 360 Tag Review`, 60],
    [`Yellow Tag Complete: ${request.yellowTag}`, 70],
    [`CM Approval: ${request.cmApproval}`, 80],
    [`Issues: ${request.bimIssues}`, 90],
    [`Voltage: ${request.voltage}`, 100],
    [`Requested Power on Date: ${request.dateRequested}`, 110]
  ];

  // Add two-column content
  leftColumn.forEach(([text, y]) => {
    doc.text(text, 20, y);
  });

  rightColumn.forEach(([text, y]) => {
    doc.text(text, pageWidth / 2 + 10, y);
  });

  // Add conditions
  doc.setFontSize(12);
  doc.text('Conditions for Energisation:', 20, 125);
  doc.setFontSize(10);
  const conditions = [
    '1. The installation must be ready and safe to energise - observation must be unrelated to safe operation.',
    '2. All relevant installation and test documentation must be completed, uploaded, reviewed and signed off by the Cx Manager.',
    '3. The parties below are in agreement that energisation can occur.'
  ];
  
  conditions.forEach((condition, index) => {
    doc.text(condition, 25, 135 + (index * 10), {
      maxWidth: pageWidth - 40
    });
  });

  // Section 2: GC Cx Approval
  addSection(doc, {
    title: 'Section 2: GC Cx Approval',
    content: 'Having reviewed/signed the equipment yellow tag I confirm the equipment/source detailed in section1 is installation complete with all relevant documentation complete, uploaded, reviewed and accepted as safe to energise.',
    signature: request.gcSignature,
    name: request.gcName,
    date: request.gcDate,
    startY: 165
  });

  // Section 3: QA/CX Approval
  addSection(doc, {
    title: 'Section 3: QA/CX Approval',
    content: 'Having Reviewed the equipment Yellow Tag I can confirm the equipment / source detailed in Section 1 can be energised and applied safety measures are appropriate. I authorise the energisation of the equipment detailed in Section 1.',
    signature: request.qaSignature,
    name: request.qaName,
    date: request.qaDate,
    startY: 215
  });

  // Section 4: Installation Responsible SAP
  addSection(doc, {
    title: 'Section 4: Installation Responsible Senior Authorised Person (SAP)',
    content: 'I confirm the equipment/source detailed in section 1 has satisfactory test documentation for energisation. All locks have been applied to ensure safe control of the electrical installation following energisation.',
    signature: request.sapSignature,
    name: request.sapName,
    date: request.sapDate,
    startY: 265
  });

  // Section 5: Energisation Declaration
  addSection(doc, {
    title: 'Section 5: Energisation Declaration',
    content: 'I confirm ALL above signatures are applied. Upon the basis I have visually inspected the equipment/source detailed in section 1 and energised. I have performed appropriate tests to verify voltage and confirm energisation.',
    signature: request.finalSignature,
    name: request.finalName,
    date: request.finalDate,
    startY: 315
  });

  return doc.output('blob');
};

// Helper function to add a section with signature
const addSection = (doc, { title, content, signature, name, date, startY }) => {
  const pageWidth = doc.internal.pageSize.width;
  
  // Add section title
  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  doc.text(title, 20, startY);
  
  // Add section content
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.text(content, 20, startY + 10, {
    maxWidth: pageWidth - 40
  });
  
  // Add signature line
  const signatureY = startY + 25;
  doc.setLineWidth(0.5);
  doc.line(20, signatureY, 80, signatureY);
  
  // Add signature if provided
  if (signature) {
    doc.addImage(signature, 'PNG', 20, signatureY - 15, 60, 15);
  }
  
  // Add name and date
  doc.setFontSize(10);
  doc.text(`Name: ${name}`, 20, signatureY + 10);
  doc.text(`Date: ${date}`, 120, signatureY + 10);
};

export default generatePDF;