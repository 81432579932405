export const BreakerSymbol = ({ x, y, size = 40, rotation = 0, isOpen = true }) => {
  const angleOffset = size / 3;
  
  return (
    <g transform={`translate(${x},${y}) rotate(${rotation + 90})`}>
      {/* Bottom connection line */}
      <line 
        x1="0" 
        y1={-size/2 - size/24} // Extending above the cross mark
        x2="0" 
        y2={-size} 
        stroke="red" 
        strokeWidth="3"
      />

      {/* Cross mark */}
      <line 
        x1={-size/4} 
        y1={-size/2} 
        x2={size/4} 
        y2={-size/2} 
        stroke="red" 
        strokeWidth="3"
      />
      
      {/* Movable part (middle) */}
      {isOpen ? (
        <line 
          x1="0" 
          y1={-size/2} 
          x2={angleOffset} 
          y2="0" 
          stroke="red" 
          strokeWidth="3"
        />
      ) : (
        <line 
          x1="0" 
          y1={-size/2} 
          x2="0" 
          y2="0" 
          stroke="red" 
          strokeWidth="3"
        />
      )}
      
      {/* Fixed vertical line (top part) */}
      <line 
        x1="0" 
        y1="0" 
        x2="0" 
        y2={size/16} 
        stroke="red" 
        strokeWidth="3"
      />
    </g>
  );
};