import React from 'react';

const AssetDetails = ({ asset }) => {
  if (!asset) return null;

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-white">{asset.Asset}</h2>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-semibold text-gray-300">Asset</h3>
          <p className="text-white">{asset.Asset || 'N/A'}</p>
        </div>
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-semibold text-gray-300">Asset Info</h3>
          <p className="text-white">{asset.AssetInfo || 'N/A'}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-semibold text-gray-300">Campus Location</h3>
          <p className="text-white">{asset.CampusLocation || 'N/A'}</p>
        </div>
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-semibold text-gray-300">Location Detail</h3>
          <p className="text-white">{asset.LocationDetail || 'N/A'}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-semibold text-gray-300">Type</h3>
          <p className="text-white">{asset.Type || 'N/A'}</p>
        </div>
        <div className="bg-gray-700 p-3 rounded">
          <h3 className="font-semibold text-gray-300">Zone/Day</h3>
          <p className="text-white">{asset['Zone/Day'] || 'N/A'}</p>
        </div>
      </div>
      <div className="bg-gray-700 p-3 rounded mb-4">
        <h3 className="font-semibold text-gray-300">Source</h3>
        <p className="text-white">{asset.source || 'N/A'}</p>
      </div>
    </div>
  );
};

export default AssetDetails;
