import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const CPSignatureModal = ({ isOpen, onClose, onSave }) => {
  const sigPad = useRef(null);

  const handleClear = () => {
    sigPad.current.clear();
  };

  const handleSave = () => {
    if (sigPad.current.isEmpty()) {
      alert('Please provide a signature before saving');
      return;
    }
    
    // Get base64 string of signature
    const signatureData = sigPad.current.toDataURL();
    onSave(signatureData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg p-6 w-[600px] max-w-full">
        <h2 className="text-xl font-bold mb-4 text-gray-100">Add Your Signature</h2>
        
        <div className="border border-gray-600 rounded-lg bg-white">
          <SignatureCanvas
            ref={sigPad}
            canvasProps={{
              className: 'signature-canvas w-full',
              width: 568,
              height: 200
            }}
          />
        </div>
        
        <div className="mt-4 flex justify-end space-x-3">
          <button
            onClick={handleClear}
            className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600"
          >
            Clear
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Save Signature
          </button>
        </div>
      </div>
    </div>
  );
};

export default CPSignatureModal;