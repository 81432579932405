import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { ChevronDown, ChevronUp } from 'lucide-react'; 
import CPSignatureModal from './CPSignatureModal'

const CPRegister = () => {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [showOnlyBlueList, setShowOnlyBlueList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [selectedRegistrationId, setSelectedRegistrationId] = useState(null);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'CPRegister'));
        const registrationsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRegistrations(registrationsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching registrations:', error);
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, []);

  const filteredRegistrations = [...registrations]
    .sort((a, b) => {
      // Sort by lastTestAttempt date in descending order
      const dateA = a.lastTestAttempt ? a.lastTestAttempt.seconds : 0;
      const dateB = b.lastTestAttempt ? b.lastTestAttempt.seconds : 0;
      return dateB - dateA;
    })
    .filter(registration => {
      if (showOnlyBlueList) {
        return registration.isAddedToBlueList;
      }
      if (searchTerm.length >= 3) {
        const searchString = searchTerm.toLowerCase();
        return (
          registration.name?.toLowerCase().includes(searchString) ||
          registration.email?.toLowerCase().includes(searchString) ||
          registration.companyName?.toLowerCase().includes(searchString) ||
          registration.jobTitle?.toLowerCase().includes(searchString) ||
          registration.phoneNumber?.toLowerCase().includes(searchString)
        );
      }
      return true;
    });

  const handleRowClick = (registrationId) => {
    setExpandedRow(expandedRow === registrationId ? null : registrationId);
  };

  const handleAddToBlueList = async (e, registrationId) => {
    e.stopPropagation();
    
    if (window.confirm('Are you sure you want to add this registration to the blue list?')) {
      try {
        const registrationRef = doc(db, 'CPRegister', registrationId);
        await updateDoc(registrationRef, {
          isAddedToBlueList: true,
          addedToBlueListDate: new Date()
        });

        // Update local state
        setRegistrations(prevRegistrations =>
          prevRegistrations.map(reg =>
            reg.id === registrationId
              ? { ...reg, isAddedToBlueList: true, addedToBlueListDate: new Date() }
              : reg
          )
        );
      } catch (error) {
        console.error('Error updating blue list status:', error);
        alert('Failed to add to blue list. Please try again.');
      }
    }
  };

  const handleDelete = async (e, registrationId) => {
    e.stopPropagation(); // Prevent row expansion when clicking delete
    
    if (window.confirm('Are you sure you want to delete this registration? This action cannot be undone.')) {
      try {
        await deleteDoc(doc(db, 'CPRegister', registrationId));
        setRegistrations(prevRegistrations => 
          prevRegistrations.filter(reg => reg.id !== registrationId)
        );
      } catch (error) {
        console.error('Error deleting registration:', error);
        alert('Failed to delete registration. Please try again.');
      }
    }
  };

  const handleSaveSignature = async (signatureData) => {
    try {
      const registrationRef = doc(db, 'CPRegister', selectedRegistrationId);
      await updateDoc(registrationRef, {
        signature: signatureData,
        signatureDate: new Date()
      });
  
      // Update local state
      setRegistrations(prevRegistrations =>
        prevRegistrations.map(reg =>
          reg.id === selectedRegistrationId
            ? { ...reg, signature: signatureData, signatureDate: new Date() }
            : reg
        )
      );
  
      setIsSignatureModalOpen(false);
      setSelectedRegistrationId(null);
      alert('Signature saved successfully');
    } catch (error) {
      console.error('Error saving signature:', error);
      alert('Failed to save signature. Please try again.');
    }
  };

  const WrongAnswersDetail = ({ wrongAnswers }) => {
    if (!wrongAnswers || wrongAnswers.length === 0) {
      return <div className="p-4 text-gray-300">No wrong answers recorded.</div>;
    }
  
    return (
      <div className="p-4 bg-gray-800 space-y-4">
        <h3 className="text-lg font-semibold text-gray-200 mb-3">Wrong Answers ({wrongAnswers.length})</h3>
        {wrongAnswers.map((answer, idx) => (
          <div key={idx} className="border border-gray-700 rounded-lg p-4 space-y-2">
            <p className="text-gray-200 font-medium">{answer.questionText}</p>
            
            <div className="space-y-1">
              <p className="text-sm text-gray-400">Options:</p>
              {answer.options.map((option, optIdx) => {
                const isUserSelected = answer.userAnswers.includes(option);
                const isCorrect = answer.correctAnswers.includes(option);
                
                return (
                  <div 
                    key={optIdx} 
                    className={`text-sm pl-4 ${
                      isUserSelected 
                        ? isCorrect 
                          ? 'text-green-400' 
                          : 'text-red-400'
                        : isCorrect 
                          ? 'text-green-400' 
                          : 'text-gray-300'
                    }`}
                  >
                    {option} {
                      isUserSelected 
                        ? isCorrect 
                          ? '✓' 
                          : '✗'
                        : isCorrect 
                          ? '(correct)'
                          : ''
                    }
                  </div>
                );
              })}
            </div>
  
            {answer.multipleAnswers && (
              <p className="text-xs text-gray-400 mt-2">Multiple answers required</p>
            )}
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4 text-gray-100">
      <h1 className="text-2xl font-bold mb-4 text-gray-100">CP Register</h1>
      
      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search..."
          className="w-full p-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          onClick={() => setShowOnlyBlueList(!showOnlyBlueList)}
          className={`ml-4 px-4 py-2 rounded-lg ${
            showOnlyBlueList 
              ? 'bg-blue-600 text-white' 
              : 'bg-gray-700 text-gray-300'
          }`}
        >
          {showOnlyBlueList ? 'Show All' : 'Show Blue List Only'}
        </button>
      </div>

      <div className="overflow-x-auto rounded-lg border border-gray-700">
        <table className="min-w-full bg-gray-800">
          <thead className="bg-gray-900">
            <tr>
              <th className="w-10 px-6 py-3"></th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">CP Number</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Company</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Job Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Phone</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Test Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Score</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {/* Get current items */}
            {filteredRegistrations
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((registration) => (
              <React.Fragment key={registration.id}>
                <tr 
                  className={`hover:bg-gray-700 transition-colors cursor-pointer ${
                    expandedRow === registration.id ? 'bg-gray-700' : ''
                  }`}
                  onClick={() => handleRowClick(registration.id)}
                >
                  <td className="px-6 py-4">
                    {registration.lastTestDetails?.wrongAnswers && (
                      <button className="text-gray-400">
                        {expandedRow === registration.id ? (
                          <ChevronUp className="h-5 w-5" />
                        ) : (
                          <ChevronDown className="h-5 w-5" />
                        )}
                      </button>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.certificationNumber || '-'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.companyName}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.jobTitle}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">{registration.phoneNumber}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      registration.testStatus === 'PASSED'
                        ? 'bg-green-100 text-green-800'
                        : registration.testStatus === 'FAILED'
                        ? 'bg-red-100 text-red-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      {registration.testStatus || 'Not Started'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {registration.rawScore != null ? (
                      <span>
                        {registration.rawScore}/20 ({registration.testScore}%)
                      </span>
                    ) : '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {registration.lastTestAttempt ? 
                      new Date(registration.lastTestAttempt.seconds * 1000).toLocaleString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })
                      : '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap space-x-2">
                    {!registration.isAddedToBlueList ? (
                      <button
                        onClick={(e) => handleAddToBlueList(e, registration.id)}
                        className="bg-blue-600 text-white px-3 py-1.5 rounded-md hover:bg-blue-700 transition-colors text-sm font-medium"
                      >
                        Add To Blue List
                      </button>
                    ) : (
                      <span className="inline-flex items-center bg-blue-600/10 text-blue-500 px-3 py-1.5 rounded-md text-sm font-medium">
                        <svg className="w-4 h-4 mr-1.5" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                        </svg>
                        On Blue List
                      </span>
                    )}
                    {registration.testStatus === 'PASSED' && !registration.signature && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedRegistrationId(registration.id);
                          setIsSignatureModalOpen(true);
                        }}
                        className="bg-green-600 text-white px-3 py-1.5 rounded-md hover:bg-green-700 transition-colors text-sm font-medium"
                      >
                        Add Signature
                      </button>
                    )}
                    {registration.signature && (
                      <span className="inline-flex items-center bg-green-600/10 text-green-500 px-3 py-1.5 rounded-md text-sm font-medium">
                        <svg className="w-4 h-4 mr-1.5" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                        </svg>
                        Signed
                      </span>
                    )}
                    <button
                      onClick={(e) => handleDelete(e, registration.id)}
                      className="bg-red-600 text-white px-3 py-1.5 rounded-md hover:bg-red-700 transition-colors text-sm font-medium"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                {expandedRow === registration.id && registration.lastTestDetails && (
                  <tr>
                    <td colSpan="9" className="bg-gray-800">
                      <WrongAnswersDetail wrongAnswers={registration.lastTestDetails.wrongAnswers} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="mt-4 flex justify-between items-center text-gray-300">
        <div>
          Showing {Math.min((currentPage - 1) * itemsPerPage + 1, filteredRegistrations.length)} to{' '}
          {Math.min(currentPage * itemsPerPage, filteredRegistrations.length)} of{' '}
          {filteredRegistrations.length} entries
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 1
                ? 'bg-gray-700 text-gray-500 cursor-not-allowed'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
          >
            Previous
          </button>
          <div className="flex items-center space-x-2">
            {[...Array(Math.ceil(filteredRegistrations.length / itemsPerPage))].map((_, idx) => (
              <button
                key={idx + 1}
                onClick={() => setCurrentPage(idx + 1)}
                className={`px-3 py-1 rounded-lg ${
                  currentPage === idx + 1
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                {idx + 1}
              </button>
            ))}
          </div>
          <button
            onClick={() => setCurrentPage(prev => 
              Math.min(prev + 1, Math.ceil(filteredRegistrations.length / itemsPerPage))
            )}
            disabled={currentPage >= Math.ceil(filteredRegistrations.length / itemsPerPage)}
            className={`px-4 py-2 rounded-lg ${
              currentPage >= Math.ceil(filteredRegistrations.length / itemsPerPage)
                ? 'bg-gray-700 text-gray-500 cursor-not-allowed'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
          >
            Next
          </button>
        </div>
      </div>
      <CPSignatureModal
          isOpen={isSignatureModalOpen}
          onClose={() => {
            setIsSignatureModalOpen(false);
            setSelectedRegistrationId(null);
          }}
          onSave={handleSaveSignature}
        />
    </div>
  );
};

export default CPRegister;
