import React, { useState, useEffect } from 'react';
import { Calendar } from 'lucide-react';
import SignaturePad from './SignaturePad';
import { doc, updateDoc,} from 'firebase/firestore';
import { db } from '../../../services/firebase'; // Adjust this import based on your Firebase setup

const GCCxApprovalForm = ({ request, updateRequestStatus }) => {
  const [formData, setFormData] = useState({
    yellowTag: '',
    bimIssues: '',
    name: '',
    date: new Date().toISOString().split('T')[0],
  });
  const [signatureData, setSignatureData] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (request.gccxApproval) {
      setFormData({
        yellowTag: request.gccxApproval.yellowTag || '',
        bimIssues: request.gccxApproval.bimIssues || '',
        name: request.gccxApproval.name || '',
        date: request.gccxApproval.date || new Date().toISOString().split('T')[0],
      });
      setSignatureData(request.gccxApproval.signature || '');
      setIsSubmitted(true);
    }
  }, [request]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSignatureSave = (dataUrl) => {
    const base64Data = dataUrl.split(',')[1];
    setSignatureData(base64Data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!signatureData) {
      alert('Please provide a signature before submitting');
      return;
    }

    if (!request || !request.id) {
      alert('Invalid request data. Please try again.');
      return;
    }

    setIsSubmitting(true);

    try {
      const requestRef = doc(db, 'EnergisationRequest', request.id);

      const updateData = {
        gccxApproval: {
          yellowTag: formData.yellowTag,
          bimIssues: formData.bimIssues,
          name: formData.name,
          date: formData.date,
          signature: signatureData,
          approvedAt: new Date().toISOString(),
        },
        status: 'GcCx',
      };

      await updateDoc(requestRef, updateData);

      // Update local state
      updateRequestStatus(request.id, 'GcCx');

      setIsSubmitted(true);
      console.log('GC Cx Approval submitted successfully');
      alert('GC Cx Approval submitted successfully');
    } catch (error) {
      console.error('Error submitting GC Cx Approval:', error);
      alert(`Failed to submit GC Cx Approval. Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderField = (label, value) => (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-white">{label}</label>
      <input
        type="text"
        value={value}
        readOnly
        className="w-full px-3 py-2 bg-gray-700 text-white rounded-md opacity-70"
      />
    </div>
  );

  if (isSubmitted) {
    return (
      <div className="space-y-4">
        {renderField("Yellow Tag Signed & Complete", formData.yellowTag)}
        {renderField("BIM ISSUES", formData.bimIssues)}
        {renderField("Name", formData.name)}
        {renderField("Date", formData.date)}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-white">Signature</label>
          <img
            src={`data:image/png;base64,${signatureData}`}
            alt="Signature"
            className="border border-gray-600 rounded-md p-2 bg-gray-700"
          />
        </div>
        <div className="text-green-500 font-medium">Form submitted successfully</div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <label className="block text-sm font-medium text-white">Yellow Tag Signed & Complete</label>
        <div className="flex space-x-4">
          {['Yes', 'No', 'Conditional'].map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="radio"
                name="yellowTag"
                value={option.toLowerCase()}
                checked={formData.yellowTag === option.toLowerCase()}
                onChange={handleInputChange}
                className="text-blue-600"
              />
              <span className="text-white">{option}</span>
            </label>
          ))}
        </div>
      </div>
      
      <div className="space-y-2">
        <label htmlFor="bimIssues" className="block text-sm font-medium text-white">BIM ISSUES</label>
        <input
          id="bimIssues"
          name="bimIssues"
          type="text"
          value={formData.bimIssues}
          onChange={handleInputChange}
          className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
          placeholder="Enter value"
        />
      </div>
      
      <div className="space-y-2">
        <label htmlFor="name" className="block text-sm font-medium text-white">Name</label>
        <input
          id="name"
          name="name"
          type="text"
          value={formData.name}
          onChange={handleInputChange}
          className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
          placeholder="Enter value"
        />
      </div>
      
      <div className="space-y-2">
        <label htmlFor="date" className="block text-sm font-medium text-white">Date</label>
        <div className="relative">
          <input
            id="date"
            name="date"
            type="date"
            value={formData.date}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
          />
          <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
      </div>
      
      <SignaturePad onSave={handleSignatureSave} />
      
      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full py-2 rounded-md transition-colors ${
          isSubmitting
            ? 'bg-gray-500 cursor-not-allowed'
            : 'bg-green-600 hover:bg-green-700 text-white'
        }`}
      >
        {isSubmitting ? 'Submitting...' : 'Submit GC Cx Approval'}
      </button>
    </form>
  );
};

export default GCCxApprovalForm;