export const loaTemplates = {
    standard: {
      name: "Standard Work",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. NO ACCESS TO ELECTRICAL PANELS DURING THIS WORK OTHER THAN
  THOSE SPECIFIED IN SECTION 1 (iii). NO OTHER WORKS ALLOWED UNDER THIS DOCUMENT. PERSONAL SUPERVISION TO BE
  CARRIED OUT BY THE SAFETY DOCUMENT RECIPIENT. THESE ROOMS ALL CONTAIN LIVE EQUIPMENT AT EITHER 110 VOLTS, 415
  VOLTS OR 10,000 VOLTS, SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT
  WORK SCOPE OR RESTRICTIONS.`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS {{rams}} NO SHARP TOOLS OR POWER TOOLS TO BE
  USED IN CLOSE PROXIMITY TO TEMPORARY POWER CABLES, WHICH ARE ALL LIVE AT 110/415 VOLTS.
  NOTE: THE 10,000 VOLT TRANSFORMERS AND ALL SWITCHGEAR IS TO BE TREATED AS LIVE, SO BE AWARE OF ALL SIGNAGE
  STATING DANGER HIGH VOLTAGE. ALL LV TEMPORARY CABLES TO BE CONSIDERED LIVE AT 110/415 VOLTS. ALL TESTING TO BE
  CARRIED OUT USING APPROVED/ CALIBRATED TEST EQUIPMENT AFTER THE LEADS HAVE BEEN INSPECTED BEFORE EVERY USE.`
    },
    ABB: {
      name: "ABB",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. ACCESS TO ABB EQUIPMENT ONLY. NO OTHER WORKS ARE ALLOWED UNDER THIS DOCUMENT. IMMEDIATE SUPERVISION TO BE CARRIED OUT BY THE SAFETY DOCUMENT RECIPIENT. THESE ROOMS ALL CONTAIN LIVE EQUIPMENT AT EITHER 110 VOLTS, 400 VOLTS, OR 22,000 VOLTS  SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.
<red>NO ACCESS TO MV SECTIONS OF 22KV LIVE PANELS.
ROOMS UNDER GOOGLE CONTROL NOW REQUIRE GOOGLE WN AUTHORISATION TO ALLOW ACCESS
IF WORKING IN BATTERY ROOMS NO WORK TO BE CARRIED OUT DIRECTLY OVER BATTERY RACKS WITHOUT PERSONAL SUPERVISION  FROM A MEMBER OF THE SAST SAP TEAM.
NO INTRUSIVE WORKS ON THE TX, BUSBAR AND MV CABLING AS ONGOING MEGGER TESTING</red>
`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS: {{rams}}. NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO TEMPORARY POWER CABLES, WHICH ARE ALL LIVE AT 110/400 VOLTS. NO STANDING ON ANY ELECTRICAL EQUIPMENT. IF USING MEWPS ENSURE A SPOTTER IN PLACE AT ALL TIMES, FOAM DISCS FITTED AND TOOL LANYARDS TO BE USED.
NOTE: THE 22,000 VOLT TRANSFORMERS AND ALL SWITCHGEAR IS TO BE TREATED AS LIVE, SO BE AWARE OF ALL SIGNAGE STATING DANGER HIGH VOLTAGE. ALL LV TEMPORARY CABLES TO BE CONSIDERED LIVE AT 110/400 VOLTS. ENSURE ALL TEST EQUIPMENT IS CALIBRATED AND IN GOOD CONDITION PRIOR TO USE INCLUDING TEST LEADS.
`
    },
    Cummins: {
      name: "Cummins",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. ACCESS TO CUMMINS EQUIPMENT ONLY. NO OTHER WORKS ARE ALLOWED UNDER THIS DOCUMENT. IMMEDIATE SUPERVISION TO BE CARRIED OUT BY THE SAFETY DOCUMENT RECIPIENT. THESE ROOMS ALL CONTAIN LIVE EQUIPMENT AT EITHER 110 VOLTS OR 400 VOLTS  SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.

ROOMS UNDER GOOGLE CONTROL NOW REQUIRE GOOGLE WN AUTHORISATION TO ALLOW ACCESS
`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS: {{rams}}. NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO TEMPORARY POWER CABLES, WHICH ARE ALL LIVE AT 110/400 VOLTS. NO STANDING ON ANY ELECTRICAL EQUIPMENT. IF USING MEWPS ENSURE A SPOTTER IN PLACE AT ALL TIMES, FOAM DISCS FITTED AND TOOL LANYARDS TO BE USED.
NOTE: ALL LV TEMPORARY CABLES TO BE CONSIDERED LIVE AT 110/400 VOLTS. ENSURE ALL TEST EQUIPMENT IS CALIBRATED AND IN GOOD CONDITION PRIOR TO USE INCLUDING TEST LEADS.
`
    },
    Chillers: {
      name: "Chillers",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. NO ACCESS TO PANELS DURING THIS WORK OTHER THAN SPECIFIED IN SECTION 1 (iii). NO OTHER WORKS ARE ALLOWED UNDER THIS DOCUMENT. IMMEDIATE SUPERVISION TO BE CARRIED OUT BY THE SAFETY DOCUMENT RECIPIENT. THESE ROOMS ALL CONTAIN LIVE EQUIPMENT AT EITHER 110 VOLTS, 230 VOLTS OR 400 VOLTS, SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS {{rams}}. NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO TEMPORARY POWER CABLES, WHICH ARE ALL LIVE AT 400 VOLTS.

NOTE: THE 22,000 VOLT TRANSFORMERS AND ALL SWITCHGEAR IS TO BE TREATED AS LIVE, SO BE AWARE OF ALL SIGNAGE STATING DANGER HIGH VOLTAGE. ALL LV TEMPORARY CABLES TO BE CONSIDERED LIVE AT 400 VOLTS. ALL TESTING TO BE CARRIED OUT USING APPROVED/CALIBRATED TEST EQUIPMENT AFTER THE LEADS HAVE BEEN INSPECTED BEFORE EVERY USE. ANYONE SEEN STANDING ON ANY CABLE OR CABLE CONTAINMENT WILL BE REMOVED FROM SITE. ALL TOOLS/EQUIPMENT AT HEIGHTS MUST BE TETHERED.  
ANY DAMAGED CABLES MUST BE REPORTED!
`
    }

  };