import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useCurrentUser from '../PermitsStatus/hooks/useCurrentUser';
import useCPUsers from '../PermitsStatus/hooks/useCPUsers';

const TransferPermitModal = ({ isOpen, onClose, onTransfer, permitData }) => {
  const { currentUser } = useAuth();
  const { userData } = useCurrentUser();
  const { searchTerm, setSearchTerm, filteredUsers, fetchCPUsers, loading: cpUsersLoading } = useCPUsers();
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    fetchCPUsers();
  }, []);
  const [formData, setFormData] = useState({
    transferredTo: '',
    recipientContactNumber: '',
    company: ''
  });

  const handleUserSelect = (user) => {
    setFormData(prev => ({
      ...prev,
      transferredTo: user.name || '',
      recipientContactNumber: user.phoneNumber || '',
      company: user.companyName || ''
    }));
    setIsSearching(false);
    setSearchTerm('');
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsSearching(value.length >= 3);
  };

  const handleSubmit = () => {
    // Validate required fields
    if (!formData.transferredTo || !formData.recipientContactNumber || !formData.company) {
        alert('Please fill in all recipient details');
        return;
    }

    if (!permitData?.issuedDetails?.issuedTo) {
        alert('Current permit holder information is missing');
        return;
    }

    if (!userData) {
        alert('Transferrer information is missing');
        return;
    }

    const transferData = {
        id: permitData.id,
        transferDetails: {
            currentHolder: {
                name: permitData.issuedDetails?.issuedTo?.name || '',
                contactNumber: permitData.issuedDetails?.issuedTo?.contactNumber || '',
                company: permitData.issuedDetails?.issuedTo?.company || ''
            },
            transferredBy: {
                userId: currentUser?.uid || '',
                name: userData?.displayName || '',
                phoneNumber: userData?.phoneNumber || '',
                company: userData?.companyName || '',
                timestamp: new Date().toISOString()
            },
            transferredTo: {
                name: formData.transferredTo,
                contactNumber: formData.recipientContactNumber,
                company: formData.company
            }
        }
    };

    console.log('TransferPermitModal - Submitting transfer data:', transferData);
    onTransfer(transferData);
};

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-md border border-gray-700">
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            Transfer Permit
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="p-4 space-y-4">
          {/* Transferrer Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100">Transferrer Details</h3>
            <div className="space-y-2">
              <div className="text-sm text-gray-200 bg-gray-800 p-3 rounded-md border border-gray-700">
                <p>Name: {userData?.name || 'Loading...'}</p>
                <p>Contact: {userData?.phoneNumber || 'N/A'}</p>
              </div>
            </div>
          </div>

          {/* Current Permit Holder Section */}
          <div className="space-y-4 mt-6">
            <h3 className="text-lg font-medium text-gray-100">Current Permit Holder</h3>
            <div className="space-y-2">
              <div className="text-sm text-gray-200 bg-gray-800 p-3 rounded-md border border-gray-700">
                <p>Name: {permitData?.issuedDetails?.issuedTo?.name || 'N/A'}</p>
                <p>Contact: {permitData?.issuedDetails?.issuedTo?.contactNumber || 'N/A'}</p>
                <p>Company: {permitData?.issuedDetails?.issuedTo?.company || 'N/A'}</p>
              </div>
            </div>
          </div>

          {/* New Recipient Section */}
          <div className="space-y-4 mt-6">
            <h3 className="text-lg font-medium text-gray-100">New Recipient Details</h3>
            <div className="space-y-3">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-300">
                  Search Recipient
                </label>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onFocus={() => setIsSearching(searchTerm.length >= 3)}
                  placeholder="Search by name (min 3 characters)..."
                  className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-2"
                />
                {isSearching && searchTerm.length >= 3 && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-gray-600 rounded-md shadow-lg max-h-60 overflow-auto">
                    {cpUsersLoading ? (
                      <div className="p-2 text-gray-400">Loading...</div>
                    ) : filteredUsers.length === 0 ? (
                      <div className="p-2 text-gray-400">No users found</div>
                    ) : filteredUsers.map((user) => (
                      <div
                        key={user.id}
                        onClick={() => handleUserSelect(user)}
                        className="p-2 hover:bg-gray-700 cursor-pointer text-gray-200"
                      >
                        <div>{user.name}</div>
                        <div className="text-sm text-gray-400">{user.companyName}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Selected Recipient
                </label>
                <div className="mt-1 p-2 block w-full rounded-md border border-gray-600 bg-gray-800 text-gray-200">
                  {formData.transferredTo || 'No recipient selected'}
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Contact Number
                </label>
                <div className="mt-1 p-2 block w-full rounded-md border border-gray-600 bg-gray-800 text-gray-200">
                  {formData.recipientContactNumber || 'N/A'}
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Company
                </label>
                <div className="mt-1 p-2 block w-full rounded-md border border-gray-600 bg-gray-800 text-gray-200">
                  {formData.company || 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2 p-4 border-t border-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-200 bg-gray-700 border border-gray-600 rounded-md hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Transfer
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferPermitModal;
