
import React from 'react';

import EnergisationRequestForm from './EnergisationRequestForm';
import EnergisationTabbedContainer from './EnergisationTabbedContainer';
//import EnergisationRequestForm from './EnergisationRequestForm';

const Etracker = () => {
  return (
    <div>

       <EnergisationTabbedContainer />
       <EnergisationRequestForm />
      
      
    </div>
  );
};

export default Etracker;
