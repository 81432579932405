import React from 'react';

function RequestInformation({ selectedRequest }) {
  if (!selectedRequest) {
    return (
      <div className="mb-6 bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-700">
        <h3 className="text-2xl font-bold mb-4 text-white">No Request Selected</h3>
        <p className="text-gray-300">Please select a request to create a permit.</p>
      </div>
    );
  }

  const InfoItem = ({ label, value }) => (
    <div className="mb-2">
      <span className="text-gray-400 font-semibold">{label}:</span>
      <span className="text-white ml-2">{value}</span>
    </div>
  );

  return (
    <div className="mb-6 bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-700">
     
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InfoItem label="Full Name" value={selectedRequest.fullName} />
      
        <InfoItem label="Company" value={selectedRequest.company} />
        <InfoItem label="Email" value={selectedRequest.email} />
        <InfoItem label="Telephone" value={selectedRequest.telephone} />
       
        <InfoItem label="Date Required" value={selectedRequest.dateRequired} />
        <InfoItem label="Location" value={Array.isArray(selectedRequest.location) ? selectedRequest.location.join(', ') : selectedRequest.location} />
        <InfoItem label="Room Names/Numbers" value={selectedRequest.roomNamesNumbers} />
        <InfoItem label="Work Type" value={selectedRequest.workType} />
        <InfoItem label="Work Duration" value={selectedRequest.workDuration} />
        <InfoItem label="Equipment" value={selectedRequest.equipment} />
        <InfoItem label="RAMS" value={selectedRequest.rams} />
        <InfoItem label="Isolation Required" value={selectedRequest.isolationRequired} />
        <InfoItem label="SAP AP Required" value={selectedRequest.sapApRequired} />
        <InfoItem label="Document Holder" value={selectedRequest.documentHolder} />
        <InfoItem label="Email Requestor" value={selectedRequest.emailRequestor} />
      </div>
      <div className="mt-4">
        <h4 className="text-lg font-semibold text-white mb-2">Work To Be Done</h4>
        <p className="text-gray-300">{selectedRequest.workToBeDone}</p>
      </div>
      <div className="mt-4">
        <h4 className="text-lg font-semibold text-white mb-2">Other Info</h4>
        <p className="text-gray-300">{selectedRequest.otherInfo}</p>
      </div>
    </div>
  );
}

export default RequestInformation;
