import React from 'react';

const TestProgress = ({ currentQuestion, totalQuestions, darkMode }) => {
  const progress = ((currentQuestion + 1) / totalQuestions) * 100;

  return (
    <div className="mb-6">
      <p className="text-sm mb-2">Question {currentQuestion + 1} of {totalQuestions}</p>
      <div className={`h-2 ${darkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full`}>
        <div 
          className={`h-full ${darkMode ? 'bg-blue-600' : 'bg-blue-500'} rounded-full`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default TestProgress;
