import React, { useState, useRef } from 'react';
import { auth, db } from '../services/firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('SAP');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const signaturePad = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      const signatureDataUrl = signaturePad.current?.isEmpty() 
        ? null 
        : signaturePad.current.toDataURL();

      await updateProfile(userCredential.user, {
        displayName: name
      });

      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email: email,
        role: role,
        name: name,
        phoneNumber: phoneNumber,
        company: company,
        signature: signatureDataUrl
      });
      navigate('/dashboard');
    } catch (error) {
      setError('Failed to create an account');
      console.error(error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0F172A]">
      <div className="max-w-md w-full p-8 space-y-8 bg-[#1E293B] rounded-2xl shadow-xl">
        <div>
          <h2 className="mt-2 text-center text-3xl font-extrabold text-white">Create Account</h2>
         
        </div>
        <form className="mt-8 space-y-4" onSubmit={handleSubmit}>
          {error && <p className="text-red-400 text-center bg-red-900/30 py-2 rounded-lg">{error}</p>}
          <div className="space-y-4">
            <div>
              <input
                type="text"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="block w-full px-4 py-3 bg-[#2D3B52] border border-[#3D4B66] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="block w-full px-4 py-3 bg-[#2D3B52] border border-[#3D4B66] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="block w-full px-4 py-3 bg-[#2D3B52] border border-[#3D4B66] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="block w-full px-4 py-3 bg-[#2D3B52] border border-[#3D4B66] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
              />
            </div>
            <div>
              <input
                type="tel"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="block w-full px-4 py-3 bg-[#2D3B52] border border-[#3D4B66] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className="block w-full px-4 py-3 bg-[#2D3B52] border border-[#3D4B66] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">Select Role</label>
              <select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="block w-full px-4 py-3 bg-[#2D3B52] border border-[#3D4B66] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
              >
                <option value="SAP">SAP</option>
                <option value="AP">AP</option>
                <option value="Admin">Admin</option>
                <option value="SAP">Mechanical SAP</option>
                <option value="AP">Mechanical AP</option>
                <option value="Admin">Control Engineer</option>
              </select>
            </div>
          </div>

          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-300 mb-2">Signature</label>
            <div className="border border-[#3D4B66] rounded-lg bg-[#2D3B52] p-2">
              <SignaturePad
                ref={signaturePad}
                canvasProps={{
                  className: "w-full h-20"
                }}
              />
            </div>
            <button
              type="button"
              onClick={() => signaturePad.current.clear()}
              className="mt-2 px-4 py-2 text-sm text-gray-300 bg-[#374151] rounded-lg hover:bg-[#4B5563] transition duration-200"
            >
              Clear Signature
            </button>
          </div>

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-200 shadow-lg shadow-blue-500/20"
            >
              Register
            </button>
          </div>
        </form>
        <div className="text-center mt-4">
          <Link to="/login" className="font-medium text-gray-400 hover:text-blue-400 transition duration-200">
            Already have an account? <span className="text-blue-500">Login</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Register;
