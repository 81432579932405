import React, { useState } from 'react';
import { FaEye, FaCheckCircle, FaTimesCircle, FaExchangeAlt, FaClipboardCheck, FaEdit } from 'react-icons/fa';
import IssuePermitModal from '../modals/IssuePermitModal';
import ClosePermitModal from '../modals/ClosePermitModal';
import TransferPermitModal from '../modals/TransferPermitModal';
import ClearancePermitModal from '../modals/ClearancePermitModal';
import EditPermitModal from '../modals/EditPermitModal';

const PermitActions = ({ 
  permit, 
  handleViewPermit, 
  handleIssuePermit, 
  handleClosePermit,
  handleTransferPermit,
  handleClearancePermit,
  handleEditPermit
}) => {
  const [isIssueModalOpen, setIsIssueModalOpen] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isClearanceModalOpen, setIsClearanceModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Modal open/close handlers
  const handleOpenIssueModal = () => setIsIssueModalOpen(true);
  const handleCloseIssueModal = () => setIsIssueModalOpen(false);
  
  const handleOpenCloseModal = () => setIsCloseModalOpen(true);
  const handleCloseCloseModal = () => setIsCloseModalOpen(false);

  const handleOpenTransferModal = () => setIsTransferModalOpen(true);
  const handleCloseTransferModal = () => setIsTransferModalOpen(false);

  const handleOpenClearanceModal = () => setIsClearanceModalOpen(true);
  const handleCloseClearanceModal = () => setIsClearanceModalOpen(false);
  
  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  // Action handlers
  const handleIssueConfirm = async (permitData) => {
    await handleIssuePermit(permitData);
    handleCloseIssueModal();
  };

  const handleCloseConfirm = async (closeData) => {
    console.log('PermitActions - handleCloseConfirm received:', closeData);
    try {
      if (!closeData?.permitId || !closeData?.closeDetails) {
        console.error('PermitActions - Invalid close data:', closeData);
        return;
      }
      
      const success = await handleClosePermit(closeData);
      if (success) {
        handleCloseCloseModal();
      }
    } catch (error) {
      console.error('PermitActions - Error in handleCloseConfirm:', error);
    }
  };

  const handleTransferConfirm = async (transferData) => {
    console.log('PermitActions - handleTransferConfirm called with:', transferData);
    try {
      await handleTransferPermit(transferData);
      handleCloseTransferModal();
    } catch (error) {
      console.error('PermitActions - Error in handleTransferConfirm:', error);
    }
  };

  const handleClearanceConfirm = async (clearanceData) => {
    console.log('PermitActions - handleClearanceConfirm called with:', clearanceData);
    try {
      if (!handleClearancePermit) {
        console.error('PermitActions - handleClearancePermit prop is not defined');
        return;
      }
      await handleClearancePermit(clearanceData);
      handleCloseClearanceModal();
    } catch (error) {
      console.error('PermitActions - Error in handleClearanceConfirm:', error);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      {/* Edit button - only for Prepared permits */}
      {permit.status === 'Prepared' && (
        <button
          onClick={handleOpenEditModal}
          className="text-yellow-500 hover:text-yellow-700"
          title="Edit Permit"
        >
          <FaEdit />
        </button>
      )}

      {/* View button - always visible */}
      <button
        onClick={() => handleViewPermit(permit)}
        className="text-blue-500 hover:text-blue-700"
        title="View Permit"
      >
        <FaEye />
      </button>

      {/* Issue button - only for Prepared permits */}
      {permit.status === 'Prepared' && (
        <button
          onClick={handleOpenIssueModal}
          className="text-green-500 hover:text-green-700"
          title="Issue Permit"
        >
          <FaCheckCircle />
        </button>
      )}

      {/* Transfer button - only for Issued permits */}
      {permit.status === 'Issued' && (
        <button
          onClick={handleOpenTransferModal}
          className="text-yellow-500 hover:text-yellow-700"
          title="Transfer Permit"
        >
          <FaExchangeAlt />
        </button>
      )}

      {/* Clearance button - for Issued or Expired permits */}
      {(permit.status === 'Issued' || permit.status === 'Expired') && (
        <button
          onClick={handleOpenClearanceModal}
          className="text-indigo-500 hover:text-indigo-700"
          title="Clear Permit"
        >
          <FaClipboardCheck />
        </button>
      )}

      {/* Close button - only for permits in Clearance status */}
      {permit.status === 'Clearance' && (
        <button
          onClick={handleOpenCloseModal}
          className="text-red-500 hover:text-red-700"
          title="Close Permit"
        >
          <FaTimesCircle />
        </button>
      )}
      
      {/* Modals */}
      <IssuePermitModal
        isOpen={isIssueModalOpen}
        onClose={handleCloseIssueModal}
        onIssue={handleIssueConfirm}
        permitData={permit}
      />

      <TransferPermitModal
        isOpen={isTransferModalOpen}
        onClose={handleCloseTransferModal}
        onTransfer={handleTransferConfirm}
        permitData={permit}
      />

      <ClearancePermitModal
        isOpen={isClearanceModalOpen}
        onClose={handleCloseClearanceModal}
        onClearance={handleClearanceConfirm}
        permitData={permit}
      />

      <ClosePermitModal
        isOpen={isCloseModalOpen}
        onClose={handleCloseCloseModal}
        onClosePermit={handleCloseConfirm}
        permitData={permit}
      />

      <EditPermitModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        onEdit={handleEditPermit}
        permitData={permit}
      />
    </div>
  );
};

export default PermitActions;
