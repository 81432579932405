import { db } from '../../../../services/firebase';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  updateDoc, 
  getDoc, 
  Timestamp, 
  arrayUnion,
  getCountFromServer,
  deleteField
} from 'firebase/firestore';

class PermitService {
  static async fetchPermits(type, status) {
    try {
      const permitsRef = collection(db, 'permits');
      const q = query(
        permitsRef,
        where('type', '==', type),
        where('status', '==', status)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error fetching permits:', error);
      throw error;
    }
  }

  static async viewPermit(permitId) {
    try {
      const permitRef = doc(db, 'permits', permitId);
      const permitDoc = await getDoc(permitRef);
      
      if (permitDoc.exists()) {
        return {
          id: permitDoc.id,
          ...permitDoc.data()
        };
      }
      return null;
    } catch (error) {
      console.error('Error fetching permit:', error);
      throw error;
    }
  }

  static async issuePermit(permitId, permitData) {
    try {
      const permitRef = doc(db, 'permits', permitId);
      
      const updateData = {
        status: 'Issued',
        issuedDetails: {
          issuedBy: {
            userId: permitData.issuedDetails.issuedBy.userId,
            timestamp: Timestamp.fromDate(new Date())
          },
          issuedTo: {
            name: permitData.issuedDetails.issuedTo.name,
            contactNumber: permitData.issuedDetails.issuedTo.contactNumber,
            company: permitData.issuedDetails.issuedTo.company
          },
          supportingDocs: permitData.issuedDetails.supportingDocs
        }
      };
      
      console.log('PermitService - Updating document with:', updateData);
      await updateDoc(permitRef, updateData);
      console.log('PermitService - Update successful');
      return true;
    } catch (error) {
      console.error('PermitService - Error issuing permit:', error);
      throw error;
    }
  }

  static async clearPermit(permitId, clearanceData) {
    console.log('PermitService - clearPermit called with:', { permitId, clearanceData });
    
    try {
      if (!permitId) {
        throw new Error('Permit ID is required');
      }

      // First verify the permit exists and is in the correct status
      const permitRef = doc(db, 'permits', permitId);
      const permitDoc = await getDoc(permitRef);
      
      if (!permitDoc.exists()) {
        throw new Error('Permit not found');
      }

      const permitStatus = permitDoc.data().status;
      if (permitStatus !== 'Issued' && permitStatus !== 'Expired') {
        throw new Error('Permit must be in Issued or Expired status to be cleared');
      }

      const updateData = {
        status: 'Clearance',
        clearanceDetails: {
          clearedBy: {
            userId: clearanceData.clearedBy.userId,
            name: clearanceData.clearedBy.name,
            phoneNumber: clearanceData.clearedBy.phoneNumber,
            timestamp: Timestamp.fromDate(new Date(clearanceData.clearedBy.timestamp))
          },
          currentHolder: {
            name: clearanceData.currentHolder.name,
            contactNumber: clearanceData.currentHolder.contactNumber,
            company: clearanceData.currentHolder.company
          },
          remarks: clearanceData.remarks,
          clearedAt: Timestamp.fromDate(new Date())
        },
        lastUpdated: Timestamp.fromDate(new Date())
      };

      console.log('PermitService - Updating document with:', updateData);
      await updateDoc(permitRef, updateData);
      console.log('PermitService - Clearance successful');
      return true;
    } catch (error) {
      console.error('PermitService - Error clearing permit:', error);
      throw error;
    }
  }

  static async closePermit(permitId, closeData) {
    console.log('1. Starting closePermit with data:', closeData);
    try {
      if (!permitId) {
        throw new Error('Permit ID is required');
      }
  
      const permitRef = doc(db, 'permits', permitId);
      const permitDoc = await getDoc(permitRef);
      
      if (!permitDoc.exists()) {
        throw new Error('Permit not found');
      }
  
      if (permitDoc.data().status !== 'Clearance') {
        throw new Error('Permit must be in Clearance status before it can be closed');
      }
  
      const updateData = {
        status: 'Closed',
        closeDetails: {
          closedBy: {
            userId: closeData.closedBy.userId,
            timestamp: Timestamp.fromDate(new Date(closeData.closedBy.timestamp))
          },
          remarks: closeData.remarks,
          closedAt: Timestamp.fromDate(new Date())
        },
        lastUpdated: Timestamp.fromDate(new Date())
      };
      
      console.log('3. Update data prepared:', updateData);
      await updateDoc(permitRef, updateData);
      console.log('4. Update successful');
      return true;
    } catch (error) {
      console.error('Error in closePermit:', error);
      throw error;
    }
  }
  static async getPermitCountsByType(type) {
    try {
      const permitsRef = collection(db, 'permits');
      const statusTypes = ['Prepared', 'Issued', 'Expired', 'Closed', 'Clearance'];
      const counts = {};

      const countQueries = statusTypes.map(async (status) => {
        const q = query(
          permitsRef,
          where('type', '==', type),
          where('status', '==', status)
        );
        
        const snapshot = await getCountFromServer(q);
        counts[status] = snapshot.data().count;
      });

      await Promise.all(countQueries);
      return counts;
    } catch (error) {
      console.error('Error getting permit counts:', error);
      throw error;
    }
  }

  static async getUserData(userId) {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        return userDoc.data();
      }
      return null;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }

  static async editPermit(permitId, permitData, userId) {
    try {
      const permitRef = doc(db, 'permits', permitId);
      await updateDoc(permitRef, {
        location: permitData.location,
        accessToElectrical: permitData.accessToElectrical,
        workToBeDone: permitData.workToBeDone,
        rams: permitData.rams,
        limitsOfWork: permitData.limitsOfWork,
        furtherPrecautions: permitData.furtherPrecautions,
        remarks: permitData.remarks,
        lastUpdated: Timestamp.fromDate(new Date()),
        preparedBy: {
          userId: userId,
          timestamp: Timestamp.fromDate(new Date())
        }
      });
      return true;
    } catch (error) {
      console.error('Error editing permit:', error);
      throw error;
    }
  }

  static async transferPermit(permitId, transferData) {
    console.log('PermitService - Starting transfer process for permit:', permitId);
    try {
      const permitRef = doc(db, 'permits', permitId);
      
      const permitDoc = await getDoc(permitRef);
      if (!permitDoc.exists()) {
        throw new Error('Permit not found');
      }

      const transferRecord = {
        fromUser: {
          name: transferData.transferDetails.currentHolder.name,
          contactNumber: transferData.transferDetails.currentHolder.contactNumber,
          company: transferData.transferDetails.currentHolder.company
        },
        toUser: {
          name: transferData.transferDetails.transferredTo.name,
          contactNumber: transferData.transferDetails.transferredTo.contactNumber,
          company: transferData.transferDetails.transferredTo.company
        },
        transferredBy: {
          userId: transferData.transferDetails.transferredBy.userId,
          name: transferData.transferDetails.transferredBy.name,
          phoneNumber: transferData.transferDetails.transferredBy.phoneNumber,
          company: transferData.transferDetails.transferredBy.company,
          timestamp: Timestamp.fromDate(new Date(transferData.transferDetails.transferredBy.timestamp))
        },
        timestamp: Timestamp.fromDate(new Date())
      };

      const updateData = {
        'issuedDetails.issuedTo': {
          name: transferData.transferDetails.transferredTo.name,
          contactNumber: transferData.transferDetails.transferredTo.contactNumber,
          company: transferData.transferDetails.transferredTo.company
        },
        transferHistory: arrayUnion(transferRecord),
        lastTransferredAt: Timestamp.fromDate(new Date()),
        lastUpdated: Timestamp.fromDate(new Date())
      };

      console.log('PermitService - Attempting to update permit with data:', updateData);
      await updateDoc(permitRef, updateData);
      console.log('PermitService - Transfer completed successfully');
      return true;
    } catch (error) {
      console.error('PermitService - Error in transferPermit:', error);
      throw error;
    }
  }

  static async fetchCPUsers() {
    try {
      const cpUsersRef = collection(db, 'CPRegister');
      const q = query(cpUsersRef, where('testPassed', '==', true));
      const querySnapshot = await getDocs(q);
      
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        phoneNumber: doc.data().phoneNumber,
        signature: doc.data().signature,
        companyName: doc.data().companyName
      }));
    } catch (error) {
      console.error('Error fetching CP users:', error);
      throw error;
    }
  }
}

export default PermitService;
