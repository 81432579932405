import React, { useState, useEffect } from 'react';
import { loaTemplates } from './loaTemplates';

const LOAForm = ({ register, permitData, setPermitData }) => {
  const today = new Date().toISOString().split('T')[0];
  const [selectedTemplate, setSelectedTemplate] = useState('standard');
  const [templateText, setTemplateText] = useState({
    limitsOfWork: loaTemplates.standard.limitsOfWork,
    furtherPrecautions: loaTemplates.standard.furtherPrecautions
  });

  // Update template text when template or RAMS changes
  useEffect(() => {
    const template = loaTemplates[selectedTemplate];
    setTemplateText({
      limitsOfWork: template.limitsOfWork,
      furtherPrecautions: template.furtherPrecautions.replace('{{rams}}', permitData.rams || '')
    });
    
    setPermitData(prev => ({
      ...prev,
      limitsOfWork: template.limitsOfWork,
      furtherPrecautions: template.furtherPrecautions.replace('{{rams}}', prev.rams || '')
    }));
  }, [selectedTemplate, permitData.rams]);

  // Handle template selection
  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  // Handle text modifications
  const handleTextChange = (field, value) => {
    setTemplateText(prev => ({
      ...prev,
      [field]: value
    }));

    setPermitData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <div className="space-y-6">
      <div className="bg-gray-900 p-6 rounded-xl shadow-lg border border-gray-700">
        <div className="grid gap-6">
          {/* Section 1: Basic Information */}
          <div>
            <h3 className="text-lg font-medium text-gray-200 mb-4">Section 1: Basic Information</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">Location</label>
                <input
                  {...register('location')}
                  placeholder="Enter location details"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">Access to </label>
                <input
                  {...register('accessToElectrical')}
                  placeholder="Specify electrical access requirements"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">Work To Be Done</label>
                <textarea
                  {...register('workToBeDone')}
                  placeholder="Describe the work in detail"
                  rows="4"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">RAMS</label>
                <input
                  {...register('rams')}
                  placeholder="Enter RAMS details"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
                />
              </div>
            </div>
          </div>

          {/* Section 2: Template Selection and Customization */}
          <div>
            <h3 className="text-lg font-medium text-gray-200 mb-4">Section 2: Safety Requirements</h3>
            <div className="space-y-4">
              {/* Template Selection */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">Work Template</label>
                <select
                  value={selectedTemplate}
                  onChange={handleTemplateChange}
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 transition duration-200"
                >
                  {Object.entries(loaTemplates).map(([key, template]) => (
                    <option key={key} value={key}>{template.name}</option>
                  ))}
                </select>
              </div>

              {/* Limits of Work Text */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Limits of Work
                  <span className="text-gray-400 text-xs ml-2">(Modify if needed)</span>
                </label>
                <textarea
                  value={templateText.limitsOfWork}
                  onChange={(e) => handleTextChange('limitsOfWork', e.target.value)}
                  rows="6"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 transition duration-200 resize-none"
                />
              </div>

              {/* Further Precautions Text */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Further Precautions
                  <span className="text-gray-400 text-xs ml-2">(Modify if needed)</span>
                </label>
                <textarea
                  value={templateText.furtherPrecautions}
                  onChange={(e) => handleTextChange('furtherPrecautions', e.target.value)}
                  rows="6"
                  className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 transition duration-200 resize-none"
                />
              </div>

              {/* Reset Button */}
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    const template = loaTemplates[selectedTemplate];
                    setTemplateText({
                      limitsOfWork: template.limitsOfWork,
                      furtherPrecautions: template.furtherPrecautions.replace('{{rams}}', permitData.rams || '')
                    });
                    setPermitData(prev => ({
                      ...prev,
                      limitsOfWork: template.limitsOfWork,
                      furtherPrecautions: template.furtherPrecautions.replace('{{rams}}', prev.rams || '')
                    }));
                  }}
                  className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-gray-200 rounded-lg transition duration-200"
                >
                  Reset to Template
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LOAForm;