import React from 'react';
import { BreakerSymbol } from './BreakerSymbol';

export const DrawingSurface = ({ 
  svgRef,
  width,
  height,
  showGuides,
  startPoint,
  lines,
  currentLine,
  breakers,
  onBreakerClick,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  onMouseLeave,
  onLineEndClick 
}) => {
  const pointsToPath = (points) => {
    if (!points || points.length < 2) return '';
    return `M ${points[0].x} ${points[0].y} L ${points[1].x} ${points[1].y}`;
  };

  const handleLineEndClick = (event, line, isStart) => {
    const point = isStart ? line.points[0] : line.points[1];
    
    // Calculate direction vector based on line points
    let dx = line.points[1].x - line.points[0].x;
    let dy = line.points[1].y - line.points[0].y;
    
    // Reverse direction if clicking start point
    if (isStart) {
      dx = -dx;
      dy = -dy;
    }
    
    // Calculate angle in degrees, considering breaker's default vertical orientation
    const rotation = Math.atan2(dy, dx) * (180 / Math.PI);
    
    onLineEndClick(point, rotation);
  };

  return (
    <svg 
      ref={svgRef}
      width={width} 
      height={height} 
      className="absolute top-0 left-0"
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
    >
      {/* Guides */}
      {showGuides && startPoint && (
        <>
          <line
            x1="0" y1={startPoint.y}
            x2={width} y2={startPoint.y}
            stroke="#666"
            strokeWidth="1"
            strokeDasharray="5,5"
            opacity="0.5"
          />
          <line
            x1={startPoint.x} y1="0"
            x2={startPoint.x} y2={height}
            stroke="#666"
            strokeWidth="1"
            strokeDasharray="5,5"
            opacity="0.5"
          />
        </>
      )}
      
      {/* Lines */}
      {lines.map((line, index) => (
        line && line.points && (
          <g key={index}>
            <path
              d={pointsToPath(line.points)}
              stroke={line.color}
              strokeWidth={line.width}
              fill="none"
            />
            {/* Clickable areas for line endpoints */}
            <circle
              cx={line.points[0].x}
              cy={line.points[0].y}
              r="8"
              fill="transparent"
              cursor="pointer"
              onClick={(e) => handleLineEndClick(e, line, true)}
            />
            <circle
              cx={line.points[1].x}
              cy={line.points[1].y}
              r="8"
              fill="transparent"
              cursor="pointer"
              onClick={(e) => handleLineEndClick(e, line, false)}
            />
          </g>
        )
      ))}
      
      {/* Current Line */}
      {currentLine && currentLine.points && (
        <path
          d={pointsToPath(currentLine.points)}
          stroke={currentLine.color}
          strokeWidth={currentLine.width}
          fill="none"
        />
      )}

{breakers.map((breaker, index) => (
        <g 
          key={`breaker-${index}`}
          onClick={() => onBreakerClick(index)}
          style={{ cursor: 'pointer' }}
        >
          <BreakerSymbol
            x={breaker.x}
            y={breaker.y}
            rotation={breaker.rotation}
            isOpen={breaker.isOpen}
          />
          {/* Invisible larger click area */}
          <circle
            cx={breaker.x}
            cy={breaker.y}
            r="15"
            fill="transparent"
          />
        </g>
      ))}
    </svg>
  );
};
