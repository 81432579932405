import React, { useState } from 'react';
import { Clock } from 'lucide-react';

const PermitMonitoring = () => {
  const [timeframe, setTimeframe] = useState('daily');

  const statsData = {
    daily: {
      LOA: { issued: 7, closed: 4 },
      EPTW: { issued: 5, closed: 3 },
      MPTW: { issued: 3, closed: 2 }
    },
    weekly: {
      LOA: { issued: 35, closed: 28 },
      EPTW: { issued: 25, closed: 20 },
      MPTW: { issued: 15, closed: 12 }
    },
    monthly: {
      LOA: { issued: 150, closed: 130 },
      EPTW: { issued: 100, closed: 85 },
      MPTW: { issued: 75, closed: 60 }
    }
  };

  const permitStats = statsData[timeframe];

  return (
    <div className="bg-gray-900 rounded-xl p-6 shadow-lg">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <Clock className="w-6 h-6 text-emerald-400 mr-2" />
          <h2 className="text-2xl font-semibold text-white">Loto Safety Documents Monitoring Stats</h2>
        </div>
        <select 
          className="bg-gray-700 text-white rounded-lg px-4 py-2 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-emerald-400"
          onChange={(e) => setTimeframe(e.target.value)}
          value={timeframe}
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>

      <div className="grid grid-cols-3 gap-6">
        {Object.entries(permitStats).map(([type, stats]) => (
          <div key={type} className="bg-gray-800 rounded-lg p-6 relative overflow-hidden">
            {/* Gradient background for the header */}
            <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-emerald-400 to-purple-400"></div>
            
            {/* Enhanced permit type header */}
            <div className="mb-6">
              <h3 className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-purple-400 bg-clip-text text-transparent">
                {type}
              </h3>
            </div>

            <div className="space-y-4">
              <div className="flex flex-col">
                <span className="text-4xl font-bold text-emerald-400">{stats.issued}</span>
                <span className="text-sm text-gray-400 mt-1">Issued</span>
              </div>
              <div className="flex flex-col">
                <span className="text-4xl font-bold text-purple-400">{stats.closed}</span>
                <span className="text-sm text-gray-400 mt-1">Closed</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PermitMonitoring;
