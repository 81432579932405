import React, { useState } from 'react';
import useCurrentUser from '../PermitsStatus/hooks/useCurrentUser';
import { useAuth } from '../../../contexts/AuthContext';

const ClosePermitModal = ({ isOpen, onClose, onClosePermit, permitData }) => {
  const { currentUser } = useAuth();
  const { userData, loading } = useCurrentUser();
  const [formData, setFormData] = useState({
    remarks: '',
  });

  if (!isOpen || !permitData) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    const closeData = {
      permitId: permitData.id,
      closeDetails: {
        closedBy: {
          userId: currentUser?.uid,
          timestamp: new Date().toISOString()
        },
        remarks: formData.remarks || ''
      }
    };
    
    console.log('Data being sent from modal:', closeData);
    onClosePermit(closeData);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={handleOverlayClick}
    >
      <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-md border border-gray-700 flex flex-col max-h-[90vh]">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            Close Permit
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-4 space-y-6">
          {/* Permit Details */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100 border-b border-gray-700 pb-2">
              Permit Details
            </h3>
            <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
              <p className="text-sm text-gray-300">Status</p>
              <p className="text-gray-100">Clearance</p>
            </div>
            <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
              <p className="text-sm text-gray-300">Date & Time</p>
              <p className="text-gray-100">{formatDate(new Date())}</p>
            </div>
          </div>

          {/* Current User Details */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100 border-b border-gray-700 pb-2">
              Cancellation By
            </h3>
            {loading ? (
              <div className="text-gray-400">Loading user details...</div>
            ) : (
              <div className="space-y-3">
                <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
                  <p className="text-sm text-gray-300">Name</p>
                  <p className="text-gray-100">{userData?.name || 'N/A'}</p>
                </div>
                <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
                  <p className="text-sm text-gray-300">Contact Number</p>
                  <p className="text-gray-100">{userData?.phoneNumber || 'N/A'}</p>
                </div>
                <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
                  <p className="text-sm text-gray-300">Company</p>
                  <p className="text-gray-100">{userData?.companyName || 'N/A'}</p>
                </div>
                {userData?.signature && (
                  <div>
                    <p className="text-sm text-gray-300 mb-2">Signature</p>
                    <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
                      <img 
                        src={userData.signature} 
                        alt="Signature" 
                        className="h-16 object-contain"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Closing Remarks */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100 border-b border-gray-700 pb-2">
              Closing Remarks
            </h3>
            <textarea
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
              className="w-full h-24 bg-gray-800 text-gray-200 border border-gray-700 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              placeholder="Enter any closing remarks..."
            />
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-2 p-4 border-t border-gray-700 bg-gray-900">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-200 bg-gray-700 border border-gray-600 rounded-md hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-red-500"
          >
            Close Permit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClosePermitModal;