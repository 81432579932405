import React, { useState, useEffect } from 'react';
import { db } from '../../services/firebase';
import { collection, getDocs } from 'firebase/firestore';
import AssetSidebar from './AssetSidebar';
import AssetDetails from './AssetDetails';
import Mimic from '../Mimic/Mimic';
import AssetEnergisationRequest from './AssetEnergisationRequest';

const AssetRegister = () => {
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [assetsPerPage] = useState(14);
  const [filterAttribute, setFilterAttribute] = useState('');
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const assetsCollection = collection(db, 'Assets');
        const assetsSnapshot = await getDocs(assetsCollection);
        const assetsList = assetsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setAssets(assetsList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching assets:", error);
        setLoading(false);
      }
    };

    fetchAssets();
  }, []);

  const handleAssetClick = (asset) => {
    setSelectedAsset(asset);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleFilterAttributeChange = (attribute) => {
    setFilterAttribute(attribute);
    setFilterValue('');
    setCurrentPage(1);
  };

  const handleFilterValueChange = (value) => {
    setFilterValue(value);
    setCurrentPage(1);
  };

  if (loading) {
    return <div className="text-center text-white mt-8">Loading assets...</div>;
  }

  return (
    <div>
      <div className="flex h-screen bg-gray-900">
        <div className="w-1/3 border-r border-gray-700">
          <AssetSidebar
            assets={assets}
            selectedAsset={selectedAsset}
            onAssetClick={handleAssetClick}
            searchTerm={searchTerm}
            onSearchChange={handleSearch}
            currentPage={currentPage}
            assetsPerPage={assetsPerPage}
            onPageChange={setCurrentPage}
            filterAttribute={filterAttribute}
            onFilterAttributeChange={handleFilterAttributeChange}
            filterValue={filterValue}
            onFilterValueChange={handleFilterValueChange}
          />
        </div>
        <div className="w-2/3 p-6">
          {selectedAsset ? (
            <AssetDetails asset={selectedAsset} />
          ) : (
            <p className="text-center text-gray-500 mt-8">Select an asset to view details</p>
          )}
        </div>
      </div>
     
    </div>
  );
};

export default AssetRegister;
