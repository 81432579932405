import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useCurrentUser from '../PermitsStatus/hooks/useCurrentUser';
import useCPUsers from '../PermitsStatus/hooks/useCPUsers';

const IssuePermitModal = ({ isOpen, onClose, onIssue, permitData }) => {
  const { currentUser } = useAuth();
  const { userData } = useCurrentUser();
  const [formData, setFormData] = useState({
    issuedTo: '',
    recipientContactNumber: '',
    company: '',
    supportingDocs: {
      sld: false,
      layoutDrawing: false,
      accessKeys: false,
      other: false
    }
  });
  const { searchTerm, setSearchTerm, filteredUsers, fetchCPUsers, loading: cpUsersLoading } = useCPUsers();
  const [isSearching, setIsSearching] = useState(false);


  useEffect(() => {
    fetchCPUsers();
  }, []);

  const handleUserSelect = (user) => {
    setFormData(prev => ({
      ...prev,
      issuedTo: user.name || '',
      recipientContactNumber: user.phoneNumber || '',
      company: user.companyName || ''
    }));
    setIsSearching(false);
    setSearchTerm('');
  };

  const handleCheckboxChange = (docType) => {
    setFormData(prev => ({
      ...prev,
      supportingDocs: {
        ...prev.supportingDocs,
        [docType]: !prev.supportingDocs[docType]
      }
    }));
  };

  const handleSubmit = () => {
    console.log('1. Submit button clicked'); // New log
  
    if (!formData.issuedTo) {
      alert('Please select a recipient');
      return;
    }
  
    const updatedPermitData = {
      id: permitData.id,
      status: 'Issued',
      issuedDetails: {
        issuedBy: {
          userId: currentUser?.uid,
          timestamp: new Date().toISOString()
        },
        issuedTo: {
          name: formData.issuedTo,
          contactNumber: formData.recipientContactNumber,
          company: formData.company
        },
        supportingDocs: formData.supportingDocs
      }
    };
  
   
    onIssue(updatedPermitData);
   
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-md border border-gray-700">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            Issue Permit
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="p-4 space-y-4">
          {/* Issuer Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100">Issuer Details</h3>
            <div className="space-y-2">
              <div className="text-sm text-gray-200 bg-gray-800 p-3 rounded-md border border-gray-700">
                <p>Name: {userData?.name || 'Loading...'}</p>
                <p>Contact: {userData?.phoneNumber || 'N/A'}</p>
              </div>
              {userData?.signature && (
                <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
                  <img 
                    src={userData.signature} 
                    alt="Signature" 
                    className="h-12 object-contain"
                  />
                </div>
              )}
            </div>
          </div>

          {/* Supporting Documentation Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100">Supporting Documentation & Items Attached</h3>
            <div className="grid grid-cols-2 gap-4 bg-gray-800 p-4 rounded-md border border-gray-700">
              <label className="flex items-center space-x-2 text-gray-200">
                <input
                  type="checkbox"
                  checked={formData.supportingDocs.sld}
                  onChange={() => handleCheckboxChange('sld')}
                  className="rounded border-gray-600 text-blue-600 focus:ring-blue-500 bg-gray-700"
                />
                <span>SLD</span>
              </label>
              <label className="flex items-center space-x-2 text-gray-200">
                <input
                  type="checkbox"
                  checked={formData.supportingDocs.layoutDrawing}
                  onChange={() => handleCheckboxChange('layoutDrawing')}
                  className="rounded border-gray-600 text-blue-600 focus:ring-blue-500 bg-gray-700"
                />
                <span>Layout Drawing</span>
              </label>
              <label className="flex items-center space-x-2 text-gray-200">
                <input
                  type="checkbox"
                  checked={formData.supportingDocs.accessKeys}
                  onChange={() => handleCheckboxChange('accessKeys')}
                  className="rounded border-gray-600 text-blue-600 focus:ring-blue-500 bg-gray-700"
                />
                <span>Access Keys</span>
              </label>
              <label className="flex items-center space-x-2 text-gray-200">
                <input
                  type="checkbox"
                  checked={formData.supportingDocs.other}
                  onChange={() => handleCheckboxChange('other')}
                  className="rounded border-gray-600 text-blue-600 focus:ring-blue-500 bg-gray-700"
                />
                <span>Other</span>
              </label>
            </div>
          </div>

          {/* Recipient Section */}
          <div className="space-y-4 mt-6">
            <h3 className="text-lg font-medium text-gray-100">Recipient Details</h3>
            <div className="space-y-3">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-300">
                  Search Recipient
                </label>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsSearching(e.target.value.length >= 3);
                  }}
                  onFocus={() => setIsSearching(searchTerm.length >= 3)}
                  placeholder="Search by name (min 3 characters)..."
                  className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-2"
                />
                {isSearching && searchTerm.length >= 3 && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-gray-600 rounded-md shadow-lg max-h-60 overflow-auto">
                    {cpUsersLoading ? (
                      <div className="p-2 text-gray-400">Loading...</div>
                    ) : filteredUsers.length === 0 ? (
                      <div className="p-2 text-gray-400">No users found</div>
                    ) : filteredUsers.map((user) => (
                      <div
                        key={user.id}
                        onClick={() => handleUserSelect(user)}
                        className="p-2 hover:bg-gray-700 cursor-pointer text-gray-200"
                      >
                        <div>{user.name}</div>
                        <div className="text-sm text-gray-400">{user.companyName}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Selected Recipient
                </label>
                <div className="mt-1 p-2 block w-full rounded-md border border-gray-600 bg-gray-800 text-gray-200">
                  {formData.issuedTo || 'No recipient selected'}
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Contact Number
                </label>
                <div className="mt-1 p-2 block w-full rounded-md border border-gray-600 bg-gray-800 text-gray-200">
                  {formData.recipientContactNumber || 'N/A'}
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Company
                </label>
                <div className="mt-1 p-2 block w-full rounded-md border border-gray-600 bg-gray-800 text-gray-200">
                  {formData.company || 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-2 p-4 border-t border-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-200 bg-gray-700 border border-gray-600 rounded-md hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Issue
          </button>
        </div>
      </div>
    </div>
  );
};

export default IssuePermitModal;
