import React, { createContext, useContext, useState } from 'react';

export const PermitContext = createContext();

export const PermitProvider = ({ children }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const refreshPermits = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  return (
    <PermitContext.Provider value={{ refreshTrigger, refreshPermits }}>
      {children}
    </PermitContext.Provider>
  );
};

export const usePermitContext = () => useContext(PermitContext);
