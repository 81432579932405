import { useState } from 'react';

export const useDrawing = (scale = 1) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentLine, setCurrentLine] = useState(null);
  const [lines, setLines] = useState([]);
  const [startPoint, setStartPoint] = useState(null);
  const [showGuides, setShowGuides] = useState(false);

  const getCoordinates = (event, svgRef) => {
    if (!svgRef.current) return null;
    
    const svg = svgRef.current;
    const point = svg.createSVGPoint();
    
    const rect = svg.getBoundingClientRect();
    point.x = (event.clientX - rect.left) / scale;
    point.y = (event.clientY - rect.top) / scale;
    
    return point;
  };

  const startDrawing = (event, svgRef, color, width) => {
    const point = getCoordinates(event, svgRef);
    if (!point) return;

    setIsDrawing(true);
    setStartPoint(point);
    setCurrentLine({
      points: [point, { ...point }],
      color,
      width
    });
    setShowGuides(true);
  };

  const draw = (event, svgRef) => {
    if (!isDrawing || !startPoint) return;
    
    const currentPoint = getCoordinates(event, svgRef);
    if (!currentPoint) return;

    const dx = Math.abs(currentPoint.x - startPoint.x);
    const dy = Math.abs(currentPoint.y - startPoint.y);
    
    let endPoint;
    if (dx > dy) {
      endPoint = { x: currentPoint.x, y: startPoint.y };
    } else {
      endPoint = { x: startPoint.x, y: currentPoint.y };
    }
    
    setCurrentLine(prev => {
      if (!prev) return null;
      return {
        ...prev,
        points: [startPoint, endPoint]
      };
    });
  };

  const endDrawing = () => {
    if (currentLine && 
        currentLine.points && 
        currentLine.points.length === 2 &&
        (currentLine.points[0].x !== currentLine.points[1].x || 
         currentLine.points[0].y !== currentLine.points[1].y)) {
      setLines(prev => [...prev, currentLine]);
    }
    setIsDrawing(false);
    setCurrentLine(null);
    setStartPoint(null);
    setShowGuides(false);
  };

  const undoLastLine = () => {
    setLines(prev => prev.slice(0, -1));
  };

  return {
    isDrawing,
    currentLine,
    lines,
    startPoint,
    showGuides,
    startDrawing,
    draw,
    endDrawing,
    undoLastLine,
    setLines
  };
};