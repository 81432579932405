import React, { useState } from 'react';
import EnergisationRequestTable from './EnergisationRequestTable';
import CompletedRequestsTable from './CompletedRequestsTable';

const EnergisationTabbedContainer = () => {
  const [activeTab, setActiveTab] = useState('inProgress');

  return (
    <div className="bg-gray-900 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <div className="mb-4">
          <button
            className={`px-4 py-2 mr-2 rounded-t-lg ${
              activeTab === 'inProgress'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
            onClick={() => setActiveTab('inProgress')}
          >
            In Progress Requests
          </button>
          <button
            className={`px-4 py-2 rounded-t-lg ${
              activeTab === 'completed'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
            onClick={() => setActiveTab('completed')}
          >
            Completed Requests
          </button>
        </div>
        <div className="bg-gray-800 rounded-b-lg p-4">
          {activeTab === 'inProgress' ? <EnergisationRequestTable /> : <CompletedRequestsTable />}
        </div>
      </div>
    </div>
  );
};

export default EnergisationTabbedContainer;