import React from 'react';

const StatusTabs = ({ activeStatus, setActiveStatus, statusCounts }) => {
  const statusTabs = ['Prepared', 'Issued', 'Expired','Clearance', 'Closed'];

  return (
    <div className="flex mb-4 bg-gray-800 rounded-lg overflow-hidden">
      {statusTabs.map((status) => (
        <button
          key={status}
          className={`
            px-4 py-2 flex-1 
            ${activeStatus === status ? 'bg-blue-600 text-white' : 'text-gray-300 hover:bg-gray-700'}
            transition-colors duration-200
          `}
          onClick={() => setActiveStatus(status)}
        >
          <div className="flex flex-col items-center">
            <span>{status}</span>
            <span className="text-xs mt-1 font-medium bg-opacity-50 px-2 py-0.5 rounded-full bg-gray-700">
              {statusCounts[status]}
            </span>
          </div>
        </button>
      ))}
    </div>
  );
};

export default StatusTabs;
