import React, { useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';

import SignaturePad from 'react-signature-canvas';

function Account() {
  const { currentUser, updateUserProfile } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const signaturePad = useRef();

  const [formData, setFormData] = useState({
    name: currentUser?.displayName || '',
    phoneNumber: currentUser?.phoneNumber || '',
    company: currentUser?.company || '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const clearSignature = () => {
    signaturePad.current.clear();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const signatureDataUrl = signaturePad.current.isEmpty() 
        ? currentUser?.signature 
        : signaturePad.current.toDataURL();

      await updateUserProfile({
        ...formData,
        signature: signatureDataUrl,
      });

      setSuccess('Profile updated successfully!');
    } catch (err) {
      setError('Failed to update profile. ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-900 text-white">
    
      <div className="flex-1">
       
        <div className="p-8">
          <h2 className="text-3xl font-bold mb-4">My Account</h2>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Email</label>
                <input
                  type="text"
                  value={currentUser.email}
                  disabled
                  className="w-full px-3 py-2 bg-gray-700 rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-gray-700 rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-gray-700 rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Company</label>
                <input
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-gray-700 rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Signature</label>
                <div className="border border-gray-600 rounded-md bg-white">
                  <SignaturePad
                    ref={signaturePad}
                    canvasProps={{
                      className: "w-full h-40"
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={clearSignature}
                  className="mt-2 px-4 py-2 text-sm bg-gray-700 rounded-md hover:bg-gray-600"
                >
                  Clear Signature
                </button>
              </div>

              {error && (
                <div className="text-red-500 text-sm">{error}</div>
              )}

              {success && (
                <div className="text-green-500 text-sm">{success}</div>
              )}

              <button
                type="submit"
                disabled={loading}
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
              >
                {loading ? 'Updating...' : 'Update Profile'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
