import React, { useState } from 'react';

const TestSubmission = ({ onSubmitTest, darkMode }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmitClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmSubmit = () => {
    setShowConfirmation(false);
    onSubmitTest();
  };

  const handleCancelSubmit = () => {
    setShowConfirmation(false);
  };

  return (
    <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} p-6 rounded-lg shadow-lg mt-6`}>
      <button 
        className={`px-6 py-2 rounded ${darkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'} text-white`}
        onClick={handleSubmitClick}
      >
        Submit Test
      </button>

      {showConfirmation && (
        <div className={`mt-4 p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-100'} rounded-lg`}>
          <p className="mb-2">Are you sure you want to submit the test?</p>
          <p className="mb-4">You won't be able to change your answers after submission.</p>
          <div className="flex space-x-4">
            <button 
              className={`px-4 py-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}
              onClick={handleConfirmSubmit}
            >
              Yes, Submit
            </button>
            <button 
              className={`px-4 py-2 rounded ${darkMode ? 'bg-gray-600 hover:bg-gray-700' : 'bg-gray-300 hover:bg-gray-400'} text-white`}
              onClick={handleCancelSubmit}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestSubmission;
