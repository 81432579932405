import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { generatePDF } from '../../utils/pdfGenerator';

const CompletedRequestsTable = () => {
  const [completedRequests, setCompletedRequests] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCompletedRequests = async () => {
      setLoading(true);
      try {
        const q = query(collection(db, 'EnergisationRequest'), where('status', '==', 'ENERGISATION'));
        const querySnapshot = await getDocs(q);
        const fetchedRequests = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          requestedPowerOnDate: doc.data().requestedPowerOnDate.toDate().toISOString().split('T')[0]
        }));
        setCompletedRequests(fetchedRequests);
      } catch (error) {
        console.error('Error fetching completed requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompletedRequests();
  }, []);

  const handleGeneratePDF = async (request) => {
    try {
      const pdfBlob = await generatePDF(request);
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Failed to generate PDF. Please try again.');
    }
  };

  return (
    <div className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-7xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-white">Completed Requests</h2>
      {loading ? (
        <div className="text-white text-center">Loading...</div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-300">
            <thead className="text-xs uppercase bg-gray-700 text-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3">Name</th>
                <th scope="col" className="px-6 py-3">Company</th>
                <th scope="col" className="px-6 py-3">Asset to Energise</th>
                <th scope="col" className="px-6 py-3">Energisation Date</th>
                <th scope="col" className="px-6 py-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {completedRequests.map((request) => (
                <tr key={request.id} className="border-b bg-gray-800 border-gray-700">
                  <td className="px-6 py-4">{request.name || 'N/A'}</td>
                  <td className="px-6 py-4">{request.company || 'N/A'}</td>
                  <td className="px-6 py-4">{request.assetToEnergise || 'N/A'}</td>
                  <td className="px-6 py-4">{request.energisationApproval?.energisationDate || 'N/A'}</td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => handleGeneratePDF(request)}
                      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Generate PDF
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CompletedRequestsTable;