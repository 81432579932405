import React, { useState, useEffect } from 'react';
import CreatePermitPopup from './CreatePermitPopUp/CreatePermitPopup';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { usePermitContext } from './PermitsStatus/context/PermitContext';

function PermitsTable() {
  const [permits, setPermits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedPermit, setSelectedPermit] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [permitToReject, setPermitToReject] = useState(null);
  
  // Get context values
  const { refreshTrigger, refreshPermits } = usePermitContext();


  const fieldsToDisplay = [
    { key: 'fullName', label: 'Full Name' },
    { key: 'dateRequired', label: 'Date Required' },
    { key: 'workToBeDone', label: 'Work To Be Done' },
    { key: 'equipment', label: 'Equipment' },
    { key: 'roomNamesNumbers', label: 'Room Names/Numbers' },
    { key: 'rams', label: 'RAMS' },
    { key: 'isolationRequired', label: 'Isolation Required' },
    { key: 'sapApRequired', label: 'SAP AP Required' }
  ];

  const fetchPermits = async () => {
    setLoading(true);
    setError(null);
    try {
      const permitsCollection = collection(db, 'permitRequests');
      const q = query(permitsCollection, where('status', 'not-in', ['Prepared', 'Rejected']));
      const permitsSnapshot = await getDocs(q);
      const permitsList = permitsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPermits(permitsList);
    } catch (err) {
      setError('Failed to fetch permits. Please try again.');
      console.error('Error fetching permits:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleRejectClick = (permit) => {
    console.log('Reject clicked for permit:', permit);
    setPermitToReject(permit);
    setShowRejectModal(true);
  };

  const handleRejectPermit = async () => {
    if (!permitToReject || !permitToReject.id) {
      console.error('No permit selected for rejection or invalid permit ID');
      setError('Invalid permit selected for rejection');
      setShowRejectModal(false);
      return;
    }

    try {
      
      
      const permitRef = doc(db, 'permitRequests', permitToReject.id);
      
      await updateDoc(permitRef, {
        status: 'Rejected',
        rejectedAt: new Date().toISOString()
      });

     
      
      // Update local state
      setPermits(prevPermits => 
        prevPermits.filter(permit => permit.id !== permitToReject.id)
      );
      
      setShowRejectModal(false);
      setPermitToReject(null);
      setError(null);
      
      // Use refreshPermits instead of refreshTrigger
      refreshPermits();
    } catch (err) {
   
      setError(`Failed to reject permit: ${err.message}`);
      setShowRejectModal(false);
    }
  };
  
  useEffect(() => {
    fetchPermits();
  }, [refreshTrigger]);

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = permits.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(permits.length / itemsPerPage);

  const formatCellContent = (field, value) => {
    if (!value) return '';
    
    if (field === 'dateOfRequest') {
      return value instanceof Date ? value.toLocaleString() : value;
    }
    
    return String(value);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'bg-green-600';
      case 'Pending':
        return 'bg-yellow-600';
      default:
        return 'bg-red-600';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="text-white">Loading permits...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-900 text-white p-4 rounded-lg mb-4">
        {error}
      </div>
    );
  }

  return (
    <div className="mb-8 bg-gray-900 text-gray-100 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-semibold mb-4 text-blue-400">Safety Document Requests</h3>
      
      <div className="overflow-x-auto mb-4">
        <table className="w-full border-collapse table-auto">
          <thead>
            <tr className="bg-gray-800">
              <th className="border border-gray-700 p-2 whitespace-nowrap">Actions</th>
              {fieldsToDisplay.map(({ label }) => (
                <th key={label} className="border border-gray-700 p-2 whitespace-nowrap">
                  {label}
                </th>
              ))}
              <th className="border border-gray-700 p-2 whitespace-nowrap">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((permit) => (
              <tr key={permit.id} className="hover:bg-gray-800 transition-colors duration-200">
                <td className="border border-gray-700 p-2 whitespace-nowrap">
                  <button 
                    className="bg-blue-600 hover:bg-blue-700 text-white p-2 rounded transition-colors duration-200 mr-2"
                    onClick={() => setSelectedPermit(permit)}
                    title="Create Permit"
                  >
                    <FaPlus />
                  </button>
                  <button 
                    className="bg-red-600 hover:bg-red-700 text-white p-2 rounded transition-colors duration-200"
                    onClick={() => handleRejectClick(permit)}
                    title="Reject Permit"
                  >
                    <FaTimes />
                  </button>
                </td>
                {fieldsToDisplay.map(({ key }) => (
                  <td key={key} className="border border-gray-700 p-2 whitespace-nowrap">
                    {formatCellContent(key, permit[key])}
                  </td>
                ))}
                <td className="border border-gray-700 p-2 whitespace-nowrap">
                  <span className={`px-2 py-1 rounded ${getStatusColor(permit.status)}`}>
                    {permit.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center">
        <div>
          <span className="mr-2">Items per page:</span>
          <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
            className="bg-gray-800 text-white p-1 rounded"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
        <div>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => setCurrentPage(i + 1)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === i + 1 ? 'bg-blue-600' : 'bg-gray-700'
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>

      {/* Simple Rejection Modal */}
      {showRejectModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md">
            <h3 className="text-lg font-medium text-white mb-4">
              Confirm Rejection
            </h3>
            <p className="text-sm text-gray-300 mb-6">
              Are you sure you want to reject this permit request? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                onClick={() => setShowRejectModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                onClick={handleRejectPermit}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Create Permit Popup */}
      {selectedPermit && (
        <CreatePermitPopup
          selectedRequest={selectedPermit}
          closePopup={() => setSelectedPermit(null)}
          refreshPermits={refreshPermits}
        />
      )}
    </div>
  );
}

export default PermitsTable;