import React from 'react';

const QuestionContainer = ({ question, onAnswerSelected, selectedAnswers, darkMode }) => {
  const handleCheckboxChange = (option) => {
    const newSelectedAnswers = selectedAnswers.includes(option)
      ? selectedAnswers.filter(answer => answer !== option)
      : [...selectedAnswers, option];
    onAnswerSelected(newSelectedAnswers);
  };

  return (
    <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg`}>
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">{question.text}</h3>
        {question.multipleAnswers && (
          <p className={`text-sm ${darkMode ? 'text-yellow-400' : 'text-yellow-600'} mb-2`}>
            Please select {question.correctAnswers.length} answers
          </p>
        )}
      </div>
      <div className="space-y-3">
        {question.options.map((option, index) => (
          <label key={index} className="flex items-center space-x-3 cursor-pointer">
            <input
              type={question.multipleAnswers ? "checkbox" : "radio"}
              name="question-option"
              value={option}
              checked={selectedAnswers.includes(option)}
              onChange={() => handleCheckboxChange(option)}
              className="form-checkbox h-5 w-5"
            />
            <span className={`${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>{option}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default QuestionContainer;