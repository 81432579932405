import React from 'react';
import { ArrowRight, Calendar } from 'lucide-react';

const LotoDasboardRequests = () => {
  const requests = [
    {
      title: "Cleaning Walls and Floor",
      location: "EYD 1",
      requestor: "David Chen",
      company: "Cleaning Sollution Ltd",
      date: "11 Dec 2024"
    },
    {
      title: "LVL 3 Testing",
      location: "WXT 1A, EYD",
      requestor: "Sarah Wilson",
      company: "PowerSystems Inc",
      date: "11 Dec 2024"
    },
    {
      title: "Flushing of Pipework",
      location: "Control Room",
      requestor: "Mike Johnson",
      company: "CoolAir Systems",
      date: "11 Dec 2024"
    }
    
  ];

  return (
    <div className="bg-gray-800 rounded-lg">
      <div className="p-4">
        <h3 className="text-lg font-medium text-emerald-400 mb-4">Latest Safety Document Requests</h3>

        <div className="space-y-3">
          {requests.map((request, index) => (
            <div 
              key={index} 
              className="p-3 rounded-lg bg-gray-750 hover:bg-gray-700 transition-all duration-200 border border-gray-700"
            >
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-medium text-white">{request.title}</span>
                <div className="flex items-center text-xs text-gray-400">
                  <Calendar className="w-3 h-3 mr-1" />
                  {request.date}
                </div>
              </div>
              <div className="space-y-1">
                <div className="flex items-center justify-between">
                  <span className="text-xs text-emerald-400">{request.location}</span>
                  <span className="text-xs text-purple-400">{request.company}</span>
                </div>
                <div className="text-xs text-gray-400">
                  Requested by: {request.requestor}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <button className="w-full flex items-center justify-center px-4 py-3 text-sm text-emerald-400 border-t border-gray-700 hover:bg-gray-700 transition-colors gap-2 font-medium">
        See all requests
        <ArrowRight className="w-4 h-4" />
      </button>
    </div>
  );
};

export default LotoDasboardRequests;