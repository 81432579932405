import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { db } from '../../services/firebase';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import ReCAPTCHA from 'react-google-recaptcha';
import logoSAST from '../assets/logo.png';

const CPRequestForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const checkExistingEmail = async (email) => {
    try {
      const registrationsRef = collection(db, 'CPRegister');
      const q = query(registrationsRef, where('email', '==', email.toLowerCase()));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        return {
          exists: true,
          message: "This email has already been registered. Please Contact SAST Office for assistance."
        };
      }
      
      return { exists: false };
    } catch (error) {
      console.error('Error checking email:', error);
      throw new Error('Failed to verify email. Please try again.');
    }
  };

  const checkRateLimit = async (email) => {
    try {
      const now = new Date();
      const fiveMinutesAgo = new Date(now.getTime() - 5 * 60000);

      const registrationsRef = collection(db, 'CPRegister');
      const recentSubmissions = query(
        registrationsRef,
        where('email', '==', email),
        where('timestamp', '>=', fiveMinutesAgo.toISOString())
      );

      const querySnapshot = await getDocs(recentSubmissions);
      return querySnapshot.size >= 3;
    } catch (error) {
      console.error('Rate limit check error:', error);
      return false;
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      setSubmitError(null);

      if (!captchaToken) {
        setSubmitError('Please complete the CAPTCHA verification');
        setIsSubmitting(false);
        return;
      }

      // Check for existing email
      const emailCheck = await checkExistingEmail(data.email);
      if (emailCheck.exists) {
        setSubmitError(emailCheck.message);
        setIsSubmitting(false);
        return;
      }

      const isRateLimited = await checkRateLimit(data.email);
      if (isRateLimited) {
        setSubmitError('Too many attempts. Please try again later.');
        setIsSubmitting(false);
        return;
      }

      // Create registration document
      const registrationsRef = collection(db, 'CPRegister');
      const newDocRef = doc(registrationsRef);
      
      const registrationData = {
        email: data.email.toLowerCase(), // Store email in lowercase for consistency
        name: data.name,
        companyName: data.companyName,
        phoneNumber: data.phoneNumber,
        jobTitle: data.jobTitle,
        registrationId: newDocRef.id,
        timestamp: new Date().toISOString(),
        status: 'pending',
        testStatus: 'not_started',
        testScore: null,
        lastTestAttempt: null,
        testAttempts: 0, // Initialize attempts counter
        attempts: [] // Initialize attempts array
      };

      // Store registration data
      await setDoc(newDocRef, registrationData);

      // Set success and navigate
      setSubmitSuccess(true);
      
      // Navigate to test with correct state
      navigate('/CPTest', { 
        state: { 
          registrationId: newDocRef.id,
          email: data.email,
          name: data.name,
          isExternalUser: true
        },
        replace: true
      });

    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitError('An error occurred while submitting the form. Please try again.');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-gray-800 p-8 rounded-xl shadow-lg">
        <div>
          <img src={logoSAST} alt="Logo" className="mx-auto h-12 w-auto" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            CP Test Registration
          </h2>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                Email Address <span className="text-red-500">*</span>
              </label>
              <input
                id="email"
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email format"
                  }
                })}
                className="mt-1 p-3 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-300">
                Full Name <span className="text-red-500">*</span>
              </label>
              <input
                id="name"
                type="text"
                {...register("name", { required: "Full name is required" })}
                className="mt-1 p-3 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-500">{errors.name.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="companyName" className="block text-sm font-medium text-gray-300">
                Company Name <span className="text-red-500">*</span>
              </label>
              <input
                id="companyName"
                type="text"
                {...register("companyName", { required: "Company name is required" })}
                className="mt-1 p-3 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {errors.companyName && (
                <p className="mt-1 text-sm text-red-500">{errors.companyName.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-300">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <input
                id="phoneNumber"
                type="tel"
                {...register("phoneNumber", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                    message: "Invalid phone number format"
                  }
                })}
                className="mt-1 p-3 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {errors.phoneNumber && (
                <p className="mt-1 text-sm text-red-500">{errors.phoneNumber.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-300">
                Job Title <span className="text-red-500">*</span>
              </label>
              <input
                id="jobTitle"
                type="text"
                {...register("jobTitle", { required: "Job title is required" })}
                className="mt-1 p-3 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {errors.jobTitle && (
                <p className="mt-1 text-sm text-red-500">{errors.jobTitle.message}</p>
              )}
            </div>

            {/* Data Consent Checkbox */}
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="consent"
                  type="checkbox"
                  {...register("consent", { 
                    required: "You must agree to the data storage consent" 
                  })}
                  className="h-4 w-4 rounded border-gray-600 bg-gray-700 text-indigo-600 focus:ring-indigo-500"
                />
              </div>
              <div className="ml-3">
                <label htmlFor="consent" className="text-sm text-gray-300">
                  I agree to the storage and processing of my personal data for the purpose of taking the CP Test and receiving results. <span className="text-red-500">*</span>
                </label>
                {errors.consent && (
                  <p className="mt-1 text-sm text-red-500">{errors.consent.message}</p>
                )}
              </div>
            </div>
          </div>

          {/* reCAPTCHA */}
          <div className="flex justify-center pt-4">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setCaptchaToken}
              theme="dark"
            />
          </div>

          {/* Submit Button */}
          <div className="pt-4">
            <button
              type="submit"
              disabled={isSubmitting || !captchaToken}
              className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                ${isSubmitting || !captchaToken ? 'bg-indigo-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'}
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {isSubmitting ? 'Submitting...' : 'Start Test'}
            </button>
          </div>
        </form>

        {/* Error and Success Messages */}
        {submitError && (
          <div className="mt-2 text-center text-sm text-red-400 bg-red-900 p-2 rounded">
            {submitError}
          </div>
        )}
        {submitSuccess && (
          <div className="mt-2 text-center text-sm text-green-400 bg-green-900 p-2 rounded">
            Registration successful! Redirecting to test...
          </div>
        )}
      </div>
    </div>
  );
};

export default CPRequestForm;
