import React from 'react';

const FeedbackModal = ({ isOpen, onClose, success, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6 relative">
        <div className={`text-center ${success ? 'text-green-600' : 'text-red-600'} mb-4`}>
          {success ? (
            <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
          ) : (
            <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          )}
          <h3 className="text-xl font-bold mt-2">
            {success ? 'Success!' : 'Error'}
          </h3>
        </div>
        <p className="text-gray-600 text-center mb-6">{message}</p>
        <button
          onClick={onClose}
          className={`w-full py-2 px-4 rounded-md text-white font-medium
            ${success ? 'bg-green-600 hover:bg-green-700' : 'bg-red-600 hover:bg-red-700'}
            transition duration-150 ease-in-out`}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default FeedbackModal;