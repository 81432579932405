import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { usePermitContext } from '../PermitsStatus/context/PermitContext';
import PermitService from '../PermitsStatus/services/PermitService';

const EditPermitModal = ({ isOpen, onClose, onEdit, permitData }) => {
  const { currentUser } = useAuth();
  const { refreshPermits } = usePermitContext();
  const [formData, setFormData] = useState({
    location: '',
    accessToElectrical: '',
    workToBeDone: '',
    rams: '',
    limitsOfWork: '',
    furtherPrecautions: '',
    remarks: ''
  });

  useEffect(() => {
    if (permitData) {
      setFormData({
        location: permitData.location || '',
        accessToElectrical: permitData.accessToElectrical || '',
        workToBeDone: permitData.workToBeDone || '',
        rams: permitData.rams || '',
        limitsOfWork: permitData.limitsOfWork || '',
        furtherPrecautions: permitData.furtherPrecautions || '',
        remarks: permitData.remarks || ''
      });
    }
  }, [permitData]);

  const handleSubmit = async () => {
    try {
      await PermitService.editPermit(permitData.id, formData, currentUser?.uid);
      onEdit && onEdit({
        id: permitData.id,
        ...formData
      });
      refreshPermits(); // Add refresh trigger
      onClose();
    } catch (error) {
      console.error('Error editing permit:', error);
      // Here you might want to show an error message to the user
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-md border border-gray-700">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            Edit Permit
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="p-4 space-y-4 max-h-[70vh] overflow-y-auto">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-300">
                Location
              </label>
              <input
                type="text"
                value={formData.location}
                onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
                className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                Access to Electrical
              </label>
              <input
                type="text"
                value={formData.accessToElectrical}
                onChange={(e) => setFormData(prev => ({ ...prev, accessToElectrical: e.target.value }))}
                className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                Work to be Done
              </label>
              <textarea
                value={formData.workToBeDone}
                onChange={(e) => setFormData(prev => ({ ...prev, workToBeDone: e.target.value }))}
                rows={4}
                className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                RAMS
              </label>
              <input
                type="text"
                value={formData.rams}
                onChange={(e) => setFormData(prev => ({ ...prev, rams: e.target.value }))}
                className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                Limits of Work
              </label>
              <textarea
                value={formData.limitsOfWork}
                onChange={(e) => setFormData(prev => ({ ...prev, limitsOfWork: e.target.value }))}
                rows={4}
                className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                Further Precautions
              </label>
              <textarea
                value={formData.furtherPrecautions}
                onChange={(e) => setFormData(prev => ({ ...prev, furtherPrecautions: e.target.value }))}
                rows={4}
                className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                Remarks
              </label>
              <textarea
                value={formData.remarks}
                onChange={(e) => setFormData(prev => ({ ...prev, remarks: e.target.value }))}
                rows={3}
                className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200"
              />
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-2 p-4 border-t border-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-200 bg-gray-700 border border-gray-600 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPermitModal;
