import { db } from '../../../../services/firebase';
import { doc, getDoc } from 'firebase/firestore';

export class UserService {
  static async getUserById(userId) {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        return userDoc.data();
      }
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      throw error;
    }
  }
}