import { useState, useEffect } from 'react';
import { UserService } from './UserService';

export const useUserDetails = (permitData) => {
  const [preparedByUser, setPreparedByUser] = useState(null);
  const [issuerData, setIssuerData] = useState(null);
  const [closedByUser, setClosedByUser] = useState(null);

  // Fetch prepared by user data
  useEffect(() => {
    const fetchPreparedByUser = async () => {
      if (permitData?.preparedBy?.userId) {
        try {
          const userData = await UserService.getUserById(permitData.preparedBy.userId);
          setPreparedByUser(userData);
        } catch (error) {
          console.error('Error fetching prepared by user data:', error);
        }
      }
    };

    fetchPreparedByUser();
  }, [permitData?.preparedBy?.userId]);

  // Fetch issuer data
  useEffect(() => {
    const fetchIssuerData = async () => {
      if (permitData?.issuedDetails?.issuedBy?.userId) {
        try {
          const userData = await UserService.getUserById(permitData.issuedDetails.issuedBy.userId);
          setIssuerData(userData);
        } catch (error) {
          console.error('Error fetching issuer data:', error);
        }
      }
    };

    fetchIssuerData();
  }, [permitData?.issuedDetails?.issuedBy?.userId]);

  // Fetch closed by user data
  useEffect(() => {
    const fetchClosedByUser = async () => {
      if (permitData?.closeDetails?.closedBy?.userId) {
        try {
          const userData = await UserService.getUserById(permitData.closeDetails.closedBy.userId);
          setClosedByUser(userData);
        } catch (error) {
          console.error('Error fetching closed by user data:', error);
        }
      }
    };

    fetchClosedByUser();
  }, [permitData?.closeDetails?.closedBy?.userId]);

  return {
    preparedByUser,
    issuerData,
    closedByUser
  };
};