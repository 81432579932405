import { useState, useEffect } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import { UserService } from './UserService';

const useCurrentUser = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser?.uid) {
        setUserData(null);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const data = await UserService.getUserById(currentUser.uid);
        setUserData(data);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching user data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  return { userData, loading, error };
};

export default useCurrentUser;
