import React from 'react';
import { useNavigate } from 'react-router-dom';

const CPTestSuccess = ({ score, totalQuestions, testCompletionTime, darkMode }) => {
  const navigate = useNavigate();

  const handleReturnToPermitRequest = () => {
    navigate('/permit-requests');
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold mb-8 text-center">Congratulations!</h1>
          <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg`}>
            <p className="text-xl mb-4">You have successfully passed the CP Test!</p>
            <p className="mb-2">Your score: {score} out of {totalQuestions}</p>
            <p className="mb-4">Test completed on: {new Date(testCompletionTime).toLocaleString()}</p>
            <p className="mb-6">You can now request permits.</p>
            <button
              onClick={handleReturnToPermitRequest}
              className={`px-4 py-2 rounded ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}
            >
              Request Permits
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CPTestSuccess;
