import React from 'react';
import { Tab } from '@headlessui/react';
import GcCxForm from './GcCxForm';
import QaQcForm from './QaQcForm';
import SapForm from './SapForm';
import EnergisationForm from './EnergisationForm';

const ExpandedRowForms = ({ request, updateRequestStatus }) => {
  const tabs = [
    { id: 'gc-cx', label: 'GC Cx and Approval', component: GcCxForm },
    { id: 'qa-qc', label: 'QA/QC Approval', component: QaQcForm },
    { id: 'sap', label: 'SAP Approval', component: SapForm },
    { id: 'energisation', label: 'Energisation', component: EnergisationForm },
  ];

  return (
    <div className="w-full max-w-3xl mx-auto bg-gray-800 text-white p-6 rounded-lg shadow-lg">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-700 p-1">
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              className={({ selected }) =>
                `w-full rounded-lg py-2.5 text-sm font-medium leading-5 
                ${
                  selected
                    ? 'bg-blue-600 text-white shadow'
                    : 'text-gray-400 hover:bg-gray-600 hover:text-white'
                }`
              }
            >
              {tab.label}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-4">
          {tabs.map((tab) => (
            <Tab.Panel key={tab.id}>
              <tab.component request={request} updateRequestStatus={updateRequestStatus} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ExpandedRowForms;