import React, { useState, useEffect } from 'react';
import { FaFileAlt, FaBolt, FaCog, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { auth,db } from '../../../services/firebase';
import { usePermitContext } from '../PermitsStatus/context/PermitContext';
import { collection, addDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { usePermitNumber } from './usePermitNumber';
import RequestInformation from './RequestInformation';
import LOAForm from './LOAForm';
import EPTWForm from './EPTWForm';
import MPTWForm from './MPTWForm';
import { loaTemplates } from './loaTemplates';

function CreatePermitPopup({ closePopup, selectedRequest }) {
  const [activeTab, setActiveTab] = useState('LOA');
  const [permitData, setPermitData] = useState({
    permitType: 'LOA', 
    location: Array.isArray(selectedRequest?.location) ? selectedRequest.location : [selectedRequest?.location].filter(Boolean),
    accessToElectrical: '',
    workToBeDone: selectedRequest?.workToBeDone || '',
    datePrepared: new Date().toISOString().split('T')[0],
    rams: selectedRequest?.rams || '',
    limitsOfWork: loaTemplates.standard.limitsOfWork,
    furtherPrecautions: loaTemplates.standard.furtherPrecautions,
  });
  
  const [showTemplate, setShowTemplate] = useState(false);
  const [showRequestInfo, setShowRequestInfo] = useState(true);
  const { getNextNumber } = usePermitNumber();
  const { refreshPermits } = usePermitContext();

  const calculateExpirationDate = (permitType) => {
    const today = new Date();
    switch (permitType) {
      case 'LOA':
      case 'MPTW':
        return new Date(today.setDate(today.getDate() + 7));
      case 'EPTW':
        return null;
      default:
        return null;
    }
  };

  const handleInputChange = (e) => {
    setPermitData({ ...permitData, [e.target.name]: e.target.value });
  };

  const handleCreatePermit = async () => {
    try {
      // Get current user
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error('No user logged in');
        return;
      }

      // Get user data from Firestore
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const userData = userDoc.data();

      // Get the next permit number for this type
      const permitNumber = await getNextNumber(permitData.permitType);
      const expirationDate = calculateExpirationDate(permitData.permitType);

      // Create request information object
      const requestInfo = {
        fullName: selectedRequest?.fullName || '',
        badgeNumber: selectedRequest?.badgeNumber || '',
        company: selectedRequest?.company || '',
        email: selectedRequest?.email || '',
        telephone: selectedRequest?.telephone || '',
        dateOfRequest: selectedRequest?.dateOfRequest || '',
        dateRequired: selectedRequest?.dateRequired || '',
        roomNamesNumbers: selectedRequest?.roomNamesNumbers || '',
        workType: selectedRequest?.workType || '',
        workDuration: selectedRequest?.workDuration || '',
        equipment: selectedRequest?.equipment || '',
      };

      // Add preparer information
      const preparerInfo = {
        userId: currentUser.uid,
        timestamp: new Date()
      };

      const newPermitData = {
        ...permitData,
        number: permitNumber,
        status: 'Prepared',
        createdAt: new Date(),
        expirationDate,
        type: permitData.permitType,
        requestInfo,
        preparedBy: preparerInfo  // Add preparer information
      };
      
      const permitsCollection = collection(db, 'permits');
      const docRef = await addDoc(permitsCollection, newPermitData);

      // Delete the original request if it exists
      if (selectedRequest?.id) {
        const permitRequestsCollection = collection(db, 'permitRequests');
        try {
          await deleteDoc(doc(permitRequestsCollection, selectedRequest.id));
          console.log('Successfully deleted request:', selectedRequest.id);
        } catch (error) {
          console.error('Error deleting request:', error);
        }
      }

      setPermitData(newPermitData);
      setShowTemplate(true);
      refreshPermits();
      closePopup(); 
    } catch (error) {
      console.error('Error creating permit:', error);
    }
};

  const handleCloseTemplate = () => {
    setShowTemplate(false);
    refreshPermits();
    closePopup();
  };

  const renderPermitForm = () => {
    const register = (name, value) => ({
      name,
      value: permitData[name],
      onChange: handleInputChange
    });

    switch (permitData.permitType) {
      case 'LOA':
        return <LOAForm register={register} permitData={permitData} setPermitData={setPermitData} />;
      case 'EPTW':
        return <EPTWForm register={register} />;
      case 'MPTW':
        return <MPTWForm register={register} />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {!showTemplate ? (
          <>
            {/* Section 1: Request Information */}
            <div className="mb-6 bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-700">
              <div 
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setShowRequestInfo(!showRequestInfo)}
              >
                <h3 className="text-2xl font-bold text-white">Request Information</h3>
                <button className="text-gray-300 hover:text-white transition-colors">
                  {showRequestInfo ? <FaChevronUp /> : <FaChevronDown />}
                </button>
              </div>
              
              {showRequestInfo && (
                <div className="mt-4">
                  <RequestInformation selectedRequest={selectedRequest} />
                </div>
              )}
            </div>

            {/* Section 2: Create Permit */}
            <div className="mb-6 bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-700">
              <h3 className="text-2xl font-bold mb-4 text-white">Create Safety Document</h3>
              <div className="mb-6">
                <label className="block text-gray-300 mb-2 text-lg">Select Type:</label>
                <div className="flex space-x-4">
                  <button
                    onClick={() => setPermitData({ ...permitData, permitType: 'LOA' })}
                    className={`flex items-center justify-center p-3 rounded-lg transition-colors duration-200 ${
                      permitData.permitType === 'LOA' ? 'bg-blue-600' : 'bg-gray-700'
                    } hover:bg-blue-500 text-white font-semibold`}
                  >
                    <FaFileAlt className="mr-2" />
                    LOA
                  </button>
                  <button
                    onClick={() => setPermitData({ ...permitData, permitType: 'EPTW' })}
                    className={`flex items-center justify-center p-3 rounded-lg transition-colors duration-200 ${
                      permitData.permitType === 'EPTW' ? 'bg-red-600' : 'bg-gray-700'
                    } hover:bg-red-500 text-white font-semibold`}
                  >
                    <FaBolt className="mr-2" />
                    EPTW
                  </button>
                  <button
                    onClick={() => setPermitData({ ...permitData, permitType: 'MPTW' })}
                    className={`flex items-center justify-center p-3 rounded-lg transition-colors duration-200 ${
                      permitData.permitType === 'MPTW' ? 'bg-green-600' : 'bg-gray-700'
                    } hover:bg-green-500 text-white font-semibold`}
                  >
                    <FaCog className="mr-2" />
                    MPTW
                  </button>
                </div>
              </div>
              <div className="bg-gray-700 p-6 rounded-lg">
                {renderPermitForm()}
              </div>
            </div>

            <div className="mt-4 flex justify-end">
              <button
                onClick={handleCreatePermit}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Create Permit
              </button>
              <button
                onClick={closePopup}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <div className="mt-4 flex justify-end">
            <button
              onClick={handleCloseTemplate}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreatePermitPopup;