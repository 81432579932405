import { useState, useMemo } from 'react';
import PermitService from '../services/PermitService';

const useCPUsers = () => {
  const [cpUsers, setCPUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchCPUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const users = await PermitService.fetchCPUsers();
      setCPUsers(users);
      setLoading(false);
      return users;
    } catch (err) {
      setError(err.message);
      setLoading(false);
      return [];
    }
  };

  const filteredUsers = useMemo(() => 
    cpUsers.filter(user =>
      user.name?.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [cpUsers, searchTerm]
  );

  return {
    cpUsers,
    loading,
    error,
    searchTerm,
    setSearchTerm,
    filteredUsers,
    fetchCPUsers
  };
};

export default useCPUsers;
