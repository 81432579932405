import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../services/firebase';
import ExpandedRowForms from './ExpandedRowForms/ExpandedRowForms'; 

const EnergisationRequestTable = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRowExpansion = (id) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const updateRequestStatus = (requestId, newStatus) => {
    setRequests(prevRequests => 
      prevRequests.map(request => 
        request.id === requestId 
          ? { ...request, status: newStatus } 
          : request
      )
    );
    
    // Remove the request if it's completed
    if (newStatus === 'ENERGISATION') {
      setRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));
    }
  };

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const q = query(collection(db, 'EnergisationRequest'), where('status', '!=', 'ENERGISATION'));
        const querySnapshot = await getDocs(q);
        const fetchedRequests = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          requestedPowerOnDate: doc.data().requestedPowerOnDate.toDate().toISOString().split('T')[0]
        }));
        setRequests(fetchedRequests);
      } catch (error) {
        console.error('Error fetching energisation requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const getStatusColor = (status) => {
    switch (status.toUpperCase()) {
      case 'PENDING':
        return 'bg-yellow-600';
      case 'GCCX':
        return 'bg-blue-600';
      case 'QA/QC':
        return 'bg-orange-600';
      case 'SAP':
        return 'bg-purple-600';
      case 'ENERGISATION':
        return 'bg-green-600';
      default:
        return 'bg-gray-600';
    }
  };

  return (
    <div className="bg-gray-900  rounded-xl shadow-2xl max-w-7xl mx-auto border border-gray-800">
      <h2 className="text-3xl font-bold mb-6 text-white bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
        Energisation Requests
      </h2>
      {loading ? (
        <div className="text-white text-center">Loading...</div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-300 border-collapse">
            <thead className="text-xs uppercase bg-gray-800/60 text-gray-300 border-b border-gray-700">
              <tr className="backdrop-blur-sm">
                <th scope="col" className="px-6 py-3">Name</th>
                <th scope="col" className="px-6 py-3">Email</th>
                <th scope="col" className="px-6 py-3">Company</th>
                <th scope="col" className="px-6 py-3">Asset to Energise</th>
                <th scope="col" className="px-6 py-3">Voltage Range</th>
                <th scope="col" className="px-6 py-3">Location</th>
                <th scope="col" className="px-6 py-3">Requested Power On Date</th>
                <th scope="col" className="px-6 py-3">Energisation Type</th>
                <th scope="col" className="px-6 py-3">Source Asset Tag</th>
                <th scope="col" className="px-6 py-3">Approved Status</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((request) => (
                <React.Fragment key={request.id}>
                  <tr 
                    className="border-b border-gray-700 bg-gray-800/40 hover:bg-gray-700/50 cursor-pointer transition-all duration-200"
                    onClick={() => toggleRowExpansion(request.id)}
                  >
                    <td className="px-6 py-4">{request.name || 'N/A'}</td>
                    <td className="px-6 py-4">{request.email || 'N/A'}</td>
                    <td className="px-6 py-4">{request.company || 'N/A'}</td>
                    <td className="px-6 py-4">{request.assetToEnergise || 'N/A'}</td>
                    <td className="px-6 py-4">{request.voltageRange || 'N/A'}</td>
                    <td className="px-6 py-4">{request.location || 'N/A'}</td>
                    <td className="px-6 py-4">{request.requestedPowerOnDate || 'N/A'}</td>
                    <td className="px-6 py-4">{request.energisationType || 'N/A'}</td>
                    <td className="px-6 py-4">{request.sourceAssetTag || 'N/A'}</td>
                    <td className="px-6 py-4">
                      <span className={`px-3 py-1.5 rounded-full text-xs font-medium ${getStatusColor(request.status || 'N/A')} shadow-lg transition-all duration-200 hover:scale-105`}>
                        {(request.status || 'N/A').toUpperCase()}
                      </span>
                    </td>
                  </tr>
                  {expandedRows[request.id] && (
                    <tr>
                      <td colSpan="10" className="px-6 py-4 bg-gray-900">
                        <ExpandedRowForms request={request} updateRequestStatus={updateRequestStatus} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EnergisationRequestTable;
